import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "./Auth";
export const Login = () => {
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });
  const auth = useAuth();

  const navigate = useNavigate();

  const [userEmail, setUserEmail] = useState("testuser1@gmail.com");
  const [userPassword, setuserPassword] = useState("password");
  const [message, setMessage] = useState("");


  const loginUser = async () => {
    const params = {
      email: userEmail,
      password: userPassword,
    };
    const options = {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "content-type": "application/json",
      }),
    };
    await fetch(process.env.REACT_APP_API_URL + "V1/login", options)
      .then((response) => response.json())
      .then((response) => {
        // Do something with response.
        
        if (response.success) {
          localStorage.setItem("_token", response.data.token);
          localStorage.setItem("username", response.data.user_name);
          localStorage.setItem("role", response.data.role);
          localStorage.setItem("id", response.data.user_id);
          localStorage.setItem("corporateId", response.data.corporate_id);
          localStorage.setItem("corporateName", response.data.corporate_name);
          navigate("/");
        } else {

          setMessage(response.message);
          setErrorStyle({
            color: "red",
            display: "block",
          });
        }
      });
  };

  return (
    <>
      <div className="login-box">
        <div className="login-logo">
          <a href="../../index2.html">
            <b>Stand</b>Strong
          </a>
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Sign in to start your session</p>
            <div className="input-group mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                value={userEmail}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setUserEmail(value);
                }}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-envelope" />
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                value={userPassword}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setuserPassword(value);
                }}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock" />
                </div>
              </div>
            </div>
            <div className="row" style={errorStyle}>
              <div className="col-12">
                <p className="text-danger">
                  Login Failed. {message}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <button
                  type="submit"
                  onClick={loginUser}
                  className="btn btn-primary btn-block"
                >
                  Sign In
                </button>
              </div>
            </div>
            <p className="mb-1">
              <Link to="/reset-password">I forgot my password</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
