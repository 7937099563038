import { Link } from "react-router-dom";

export function Sidebar() {
  return (
    <>
      <aside
        className='main-sidebar sidebar-dark-primary elevation-4'
        style={{
          background: "transparent linear-gradient(0deg, #2C2A66 0%, #4D46FF 100%) 0% 0% no-repeat padding-box",
        }}>
        <a href='index3.html' className='brand-link'>
          <img src='dist/img/SS_App_Icon.png' alt='QWERTY' className='brand-image img-circle elevation-3' style={{ opacity: ".8" }} />
          <span className='brand-text font-weight-light'>Stand Strong</span>
        </a>

        <div className='sidebar'>
          <div className='user-panel mt-3 pb-3 mb-3 d-flex'>
            <div className='info'>
              <a
                className='d-block'
                style={{
                  textTransform: "capitalize",
                  fontSize: "20px",
                }}>
                {" "}
                {(() => {
                  if (localStorage.getItem("corporateName") !== "undefined") {
                    return localStorage.getItem("corporateName");
                  }
                })()}
              </a>
            </div>
          </div>

          <nav className='mt-2'>
            <ul className='nav nav-pills nav-sidebar flex-column' data-widget='treeview' role='menu' data-accordion='false'>
              <li className='nav-item menu-open'>
                <Link to='/' className='nav-link'>
                  <i className='nav-icon fas fa-tachometer-alt'></i>
                  <p>Dashboard</p>
                </Link>
              </li>

              {(() => {
                if (localStorage.getItem("role") === '1') {
                  return (
                    <li className='nav-item menu-open'>
                      <Link to='/admins' className='nav-link'>
                        <i className='nav-icon fas fa-user'></i>
                        <p>Admin</p>
                      </Link>
                    </li>
                  );
                }
              })()}

              {(() => {
                if (localStorage.getItem("role") === '1') {
                  return (
                    <li className='nav-item menu-open'>
                      <Link to='/corporations' className='nav-link'>
                        <i className='nav-icon fas fa-building'></i>
                        <p>Corporations</p>
                      </Link>
                    </li>
                  );
                }
              })()}

              {(() => {
                if (localStorage.getItem("role") === '1' || localStorage.getItem("role") === '2') {
                  return (
                    <li className='nav-item menu-open'>
                      <Link to='/corporate-users' className='nav-link'>
                        <i className='nav-icon fas fa-users'></i>
                        <p>Corp Users</p>
                      </Link>
                    </li>
                  );
                }
              })()}

              {(() => {
                if (localStorage.getItem("role") === '1') {
                  return (
                    <li className='nav-item menu-open'>
                      <Link to='/individual-users' className='nav-link'>
                        <i className='nav-icon fas fa-users'></i>
                        <p>Individual Users</p>
                      </Link>
                    </li>
                  );
                }
              })()}

              {(() => {
                if (false) {
                  return (
                    <li className='nav-item menu-open'>
                      <Link to='/experiments' className='nav-link'>
                        <i className='nav-icon fas fa-flag'></i>
                        <p>Explore</p>
                      </Link>
                    </li>
                  );
                }
              })()}
              {(() => {
                if (false) {
                  return (
                    <li className='nav-item menu-open'>
                      <Link to='/category/all' className='nav-link'>
                        <i className='nav-icon fas fa-bars'></i>
                        <p>Categories</p>
                      </Link>
                    </li>
                  );
                }
              })()}
              {(() => {
                if (localStorage.getItem("role") === '1' || localStorage.getItem("role") === '2') {
                  return (
                    <li className='nav-item menu-open'>
                      <Link to='/charity/all' className='nav-link'>
                        <i className='nav-icon fas fa-donate'></i>
                        <p>Charity</p>
                      </Link>
                    </li>
                  );
                }
              })()}
              {(() => {
                if (localStorage.getItem("role") === '1' || localStorage.getItem("role") === '2') {
                  return (
                    <li className='nav-item menu-open'>
                      <Link to='/challenges' className='nav-link' state={{ type: "sidebar" }}>
                        <i className='nav-icon fas fa-tasks'></i>
                        <p>Challenges</p>
                      </Link>
                    </li>
                  );
                }
              })()}
              {(() => {
                return (
                  <li className='nav-item menu-open'>
                    <Link
                      to={localStorage.getItem("role") === '2' ? "/reports" : "/corporations"}
                      className='nav-link'
                      state={{ type: localStorage.getItem("role") == 2 ? "sidebar" : "corporation_report" }}>
                      <i className='nav-icon fas fa-file-alt'></i>
                      <p>Reports</p>
                    </Link>
                  </li>
                );
              })()}
              {(() => {
                if (localStorage.getItem("role") === '1') {
                  return (
                    <li className='nav-item menu-open'>
                      <Link to='/announcement/all' className='nav-link'>
                        <i className='nav-icon fas fa-bullhorn'></i>
                        <p>Announcement</p>
                      </Link>
                    </li>
                  );
                }
              })()}
              {(() => {
                if (localStorage.getItem("role") === '1') {
                  return (
                    <li className='nav-item menu-open'>
                      <Link to='/milestones/all' className='nav-link'>
                        <i className='nav-icon fas fa-flag'></i>
                        <p>Milestones</p>
                      </Link>
                    </li>
                  );
                }
              })()}
              {(() => {
                if (localStorage.getItem("role") === '1') {
                  return (
                    <li className='nav-item menu-open'>
                      <Link to='/streaks/all' className='nav-link'>
                        <i className='nav-icon fas fa-history'></i>
                        <p>Streaks</p>
                      </Link>
                    </li>
                  );
                }
              })()}
              {(() => {
                if (localStorage.getItem("role") === '1') {
                  return (
                    <li className='nav-item menu-open'>
                      <Link to='/badges/all' className='nav-link'>
                        <i className='nav-icon fas fa-id-badge'></i>
                        <p>Badges</p>
                      </Link>
                    </li>
                  );
                }
              })()}
              {(() => {
                if (localStorage.getItem("role") === '1') {
                  return (
                    <li className='nav-item menu-open'>
                      <Link to='/billing/all' className='nav-link'>
                        <i className='nav-icon fas fa-file-invoice'></i>
                        <p>Billing</p>
                      </Link>
                    </li>
                  );
                }
              })()}
              {(() => {
                if (localStorage.getItem("role") === '1') {
                  return (
                    <li className='nav-item menu-open'>
                      <Link to='/blog/all' className='nav-link'>
                        <i className='nav-icon fas fa-blog'></i>
                        <p>Blog</p>
                      </Link>
                    </li>
                  );
                }
              })()}
              {(() => {
                if (localStorage.getItem("role") === '1') {
                  return (
                    <li className='nav-item menu-open'>
                      <Link to='/term-and-conditions' className='nav-link'>
                        <i className='nav-icon fas fa-file'></i>
                        <p>Term and Conditions</p>
                      </Link>
                    </li>
                  );
                }
              })()}

              {(() => {
                if (localStorage.getItem("role") === '2') {
                  return (
                    <li className='nav-item menu-open'>
                      <Link to='/departments/all' className='nav-link'>
                        <i className='nav-icon fas fa-blog'></i>
                        <p>Department</p>
                      </Link>
                    </li>
                  );
                }
              })()}
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
}
