import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useSearchParams,useNavigate } from "react-router-dom";

export default function CorporateAdmin() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [corporateId, setCorporateId] = useState(searchParams.get("id"));

  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };

  ////////////////////////////////
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(true);

  const navigate = useNavigate();
console.log(data);
  const columns = [
    {
      id: "id",
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      id: "name",
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      id: "email",
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      cell: (row) => (
        <>
          <Link
            className="btn btn-info btn-sm btn-space-custom"
            to={`/corporate-admin/edit?id=${row.id}`}
          >
            Edit
          </Link>{" "}
          {row.id !== localStorage.getItem("id") ? (
            <>
              <button
                className="btn btn-danger btn-sm btn-space-custom"
                onClick={(e) => {
                  handleBlock(e, row.id);
                }}
              >
                {row.status ? "Block" : "Unblock"}
              </button>{" "}
            </>
          ) : (
            ""
          )}
          {row.id !== localStorage.getItem("id") ? (
            <>
              <button
                className="btn btn-primary btn-sm btn-space-custom"
                onClick={(e) => {
                  handleVerify(e, row.id);
                }}
                disabled={row.email_verified_at !== null && row.email_verified_at !== ""}
              >
                {row.email_verified_at === null || row.email_verified_at === "" ? "Email Verify" : "Verified"}
              </button>
            </>
          ) : (
            ""
          )}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      // button: true,
    },
  ];


  const handleBlock = async (e, id) => {
     
    const params = {
      user_id: id,
    };
    await axios
      .post(
        process.env.REACT_APP_API_URL + `V1/user/handle-block-user`,
        params,
        config
      )
      .then((response) => {
        setMessage(response.data.message);
        setShowMessage(true);
        if (e.target.innerHTML === "Block") {
          e.target.innerHTML = "Unblock";
        } else {
          e.target.innerHTML = "Block";
        }
        setTimeout(function () {
          setShowMessage(false);
        }, 3000);
      });
  };

  const handleVerify = async (e, id) => {
    const params = {
      id: id,
    };
    await axios
      .post(
        process.env.REACT_APP_API_URL + `V1/user/reverify-corp-admin-email`,
        params,
        config
      )
      .then((response) => {
        setMessage(response.data.message);
        setShowMessage(true);

        if (e.target.innerHTML === "Verify") {
        //   e.target.innerHTML = "Verified";
        // } else {
        //   e.target.innerHTML = "Verify";
        }
        setTimeout(function () {
          setShowMessage(false);
        }, 3000);
      });
  };

  const fetchUsers = async (page) => {
    try { 
      if (corporateId !== 0) {
        setLoading(true);

        const response = await axios.get(
          process.env.REACT_APP_API_URL +
            `V1/user/get-all-corp-admin?page=${page}&per_page=${perPage}&delay=1&id=` +
            corporateId,
          config
        );

        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
      }
   }
   catch (error) {
    if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login"); 
       
    } else {
      console.error('API error:', error.message);
    }
  }
  };

  const handlePageChange = (page) => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const response = await axios.post(
      process.env.REACT_APP_API_URL +
        `V1/user/get-all-corp-admin?type=2&page=${page}&per_page=${newPerPage}&delay=1`,
      config
    );

    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const handleSearcFilter = async (search, newPerPage, page) => {
    setLoading(true);

    const response = await axios.get(
      process.env.REACT_APP_API_URL +
        `V1/user/get-all-corp-admin?page=${page}&per_page=${perPage}&delay=1&id=${corporateId}&search=${search}`,
      config
    );

    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    setCorporateId(searchParams.get("id"));
    fetchUsers(1); // fetch page 1 of users
  }, []);
  ////////////////////////////////
  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Corporation Admin</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Corporation Admin</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-2 my-0">
                {(() => {
                  if (searchParams.get("id")) {
                    const id = searchParams.get("id");
                    return (
                      <>
                        <Link to={`/corporate-admin/create?id=${id}`}>
                          <button className="btn btn-primary">
                            Add New Corporation Admin
                          </button>
                        </Link>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <Link to="/corporate-admin/create">
                          <button className="btn btn-primary">
                            Add New Corporation Admin
                          </button>
                        </Link>
                      </>
                    );
                  }
                })()}
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Search"
                            onChange={(e) => handleSearcFilter(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        {showMessage ? <p>{message}</p> : <></>}
                      </div>
                      <div className="col-md-7 mr-0">
                        <Link to="/corporate-admin/import">
                          <button className="btn btn-primary float-right">
                            Import Corporation Admin
                          </button>
                        </Link>
                      </div>
                    </div>
                    <DataTable
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      // defaultSortFieldId="id"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
