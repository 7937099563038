import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getSpecificChallengeReport } from "../../../Api/EndpointCalls/EndpointCalls";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { downloadAsPDF } from "../../../Utils/HelperFunctions";

const ChallengeReport = () => {
  const [reportData, setReportData] = useState({
    data: null,
    loading: true,
  });
  const params = useParams();

  const userId = localStorage.getItem("id");
  const corporateId = localStorage.getItem("corporateId");

  const columnsTimeStoodTable = [
    {
      id: "id",
      name: "Id",
      selector: (row, i) => row.depart_id || i + 1,
      sortable: true,
    },
    {
      id: "teamName",
      name: "Department Name",
      selector: (row) => row.depart_name,
      // sortable: true,
    },
    {
      id: "teamStood",
      name: "Team Time Stood (Minutes)",
      selector: (row) => `${row.team_stood}`,
      sortable: true,
    },
    {
      id: "userActive",
      name: "User Active",
      selector: (row) => row.user_active,
      sortable: true,
    },
  ];

  const fetchChallengeReport = async () => {
    try {
      const res = await getSpecificChallengeReport(params?.challengeId, userId, corporateId);
      if (res?.status === 200) {
        setReportData({ data: res?.data?.data, loading: false });
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchChallengeReport();
  }, []);

  return (
    <div className='content-wrapper p-4'>
      <div className='row'>
        <div className='col d-flex justify-content-end'>
          <button
            className='download-report-pdf-button btn btn-primary'
            onClick={() => {
              downloadAsPDF(document.getElementById("content-report"));
            }}>
            <div className='row'>
              <i className='fa fa-file-download fa-inverse download-pdf-icon'></i>
              <p className='pe-1 m-0'>Download PDF</p>
            </div>
          </button>
        </div>
      </div>
      <div className='' id='content-report'>
        <h4 className='text-center mb-3'>Challenge Report</h4>
        <div className='card shadow  border-top-yellow'>
          <div className='card-body'>
            <div className='col-md-14'>
              <div className='row no-gutters align-items-center mb-2 p-2'>
                <div className='col-md-4 m-0'>
                  <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Challenge Id</div>
                  <div className='h5 mb-0 font-weight-bold text-gray-800'>
                    {reportData?.data?.challenge_id ? reportData?.data?.challenge_id : "N/A"}
                  </div>
                </div>
                <div className='col-md-4 m-0'>
                  <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Name</div>
                  <div className='h5 mb-0 font-weight-bold text-gray-800'>
                    {reportData?.data?.challenge_name ? reportData?.data?.challenge_name : "N/A"}
                  </div>
                </div>
                {/* <div className='col-md-4 m-0'>
                    <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Stand Time</div>
                    <div className='h5 mb-0 font-weight-bold text-gray-800'>{"as"}</div>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          {/* Top Row - Cards */}
          <div className='col-xl-6 col-md-6 mb-4'>
            <div className='card shadow border-top-users-active-in-chalenge-primary shadow h-100 py-2'>
              <div className='col-auto justify-content-center'>
                <div className='d-flex flex-column justify-content-center'>
                  <span className='fa-stack fa-lg align-self-center mb-2'>
                    <i className='fa fa-circle fa-stack-2x' style={{ color: "#7a3ec3" }}></i>
                    <i className='fa fa-tasks fa-stack-1x fa-inverse'></i>
                  </span>
                  <p className='align-self-center font-weight-bold'>TEAM WITH MOST STANDS</p>
                </div>
              </div>
              <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                <div className='col m-1'>
                  <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>ID</div>
                  <div className='h5 mb-0 font-weight-bold text-gray-800'>
                    {reportData?.data?.depart_most_stand?.depart_id ? reportData?.data?.depart_most_stand?.depart_id : "N/A"}
                  </div>
                </div>
                <div className='col m-1'>
                  <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Name</div>
                  <div className='h5 mb-0 font-weight-bold text-gray-800'>
                    {reportData?.data?.depart_most_stand?.depart_name ? reportData?.data?.depart_most_stand?.depart_name : "N/A"}
                  </div>
                </div>
                <div className='col m-1'>
                  <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Stands</div>
                  <div className='h5 mb-0 font-weight-bold text-gray-800'>
                    {reportData?.data?.depart_most_stand?.stand ? reportData?.data?.depart_most_stand?.stand : "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-6 col-md-6 mb-4'>
            <div className='card border-top-users-one shadow h-100 py-2'>
              <div className='col-auto justify-content-center'>
                <div className='d-flex flex-column justify-content-center'>
                  <span className='fa-stack fa-lg align-self-center mb-2'>
                    <i className='fa fa-circle fa-stack-2x' style={{ color: "#f36a26" }}></i>
                    <i className='fa fa-users fa-stack-1x fa-inverse'></i>
                  </span>
                  <p className='align-self-center font-weight-bold'>TEAM WITH MOST USERS ACHIEVING THEIR DAILY STAND GOAL</p>
                </div>
              </div>
              <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                <div className='col m-1'>
                  <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>ID</div>
                  <div className='h5 mb-0 font-weight-bold text-gray-800'>
                    {reportData?.data?.depart_daily_goal_achieve?.depart_id ? reportData?.data?.depart_daily_goal_achieve?.depart_id : "N/A"}
                  </div>
                </div>
                <div className='col m-1'>
                  <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>NAME</div>
                  <div className='h5 mb-0 font-weight-bold text-gray-800'>
                    {reportData?.data?.depart_daily_goal_achieve?.depart_name ? reportData?.data?.depart_daily_goal_achieve?.depart_name : "N/A"}
                  </div>
                </div>
                <div className='col m-1'>
                  <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>USER COUNT</div>
                  <div className='h5 mb-0 font-weight-bold text-gray-800'>
                    {reportData?.data?.depart_daily_goal_achieve?.stand ? reportData?.data?.depart_daily_goal_achieve?.stand : "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Bottom Row - Total Time Stood Table */}
        <div className='row p-0'>
          <div className='col-md-12 '>
            <div className='card shadow  border-top-users-daily-goal-primary'>
              <div className='card-body'>
                <h4 className='text-center'>Total Time Stood (Each Team)</h4>
                <div className='row'>
                  <DataTable
                    columns={columnsTimeStoodTable}
                    data={reportData?.data?.depart ? reportData?.data?.depart : []}
                    // progressPending={reportData?.loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeReport;
