export function Footer() {
  return (
    <>
      <footer className="main-footer">
        All rights reserved.
      </footer>
      <aside className="control-sidebar control-sidebar-dark"></aside>
    </>
  );
}
