import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getIndividualTeamReport } from "../../../Api/EndpointCalls/EndpointCalls";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { downloadAsPDF } from "../../../Utils/HelperFunctions";

const DepartmentReport = () => {
  const [reportData, setReportData] = useState({ data: null, loading: true });
  const params = useParams();

  const userId = localStorage.getItem("id");
  const corporateId = localStorage.getItem("corporateId");

  const columnsPercentageTeamUser = [
    {
      id: "profilePic",
      name: "",
      selector: (row, i) => {
        return (
          <div className='table-profile-pic'>
            <img alt="" src={`${process.env.REACT_APP_IMAGE_URL}storage/${row?.profile_pic}`} />
          </div>
        );
      },
      sortable: true,
    },
    {
      id: "id",
      name: "Id",
      selector: (row, i) => row.user_id,
      sortable: true,
    },
    {
      id: "name",
      name: "Name",
      selector: (row) => row.name.charAt(0).toUpperCase() + row.name.slice(1),
      sortable: true,
    },
    {
      id: "percentageGoalAchieve",
      name: "Percentage Goal Achieve",
      selector: (row) => row.per_goal_achieve + "%",
      // sortable: true,
    },
  ];

  const columnsTopStander = [
    {
      id: "profilePic",
      name: "",
      selector: (row, i) => {
        return (
          <div className='table-profile-pic'>
            <img alt="" src={`${process.env.REACT_APP_IMAGE_URL}storage/${row?.profile_pc}`} />
          </div>
        );
      },
      sortable: true,
    },
    {
      id: "name",
      name: "Name",
      selector: (row) => row.name.charAt(0).toUpperCase() + row.name.slice(1),
      sortable: true,
    },
    {
      id: "rank",
      name: "Rank",
      selector: (row) => row.rank,
      // sortable: true,
    },
  ];

  const fetchChallengeReport = async () => {
    try {
      const res = await getIndividualTeamReport(userId, params?.departmentId, corporateId);
      setReportData({ data: res?.data?.data, loading: false });
    } catch (error) {}
  };

  useEffect(() => {
    fetchChallengeReport();
  }, []);

  return (
    <div className='content-wrapper p-4'>
      <div className='col d-flex justify-content-end mb-2'>
        <button
          className='download-report-pdf-button btn btn-primary'
          onClick={() => {
            downloadAsPDF(document.getElementById("content-report"));
          }}>
          <div className='row'>
            <i className='fa fa-file-download fa-inverse download-pdf-icon'></i>
            <p className='pe-1 m-0'>Download PDF</p>
          </div>
        </button>
      </div>
      {/* Top Row - Cards */}
      <div className='' id='content-report'>
        <h4 className='text-center'>Individual Team Report</h4>
        <div className='card shadow border-top-yellow'>
          <div className='card-body'>
            <div className='col-md-14'>
              <div className='row no-gutters align-items-center mb-2 p-2'>
                <div className='col-md-4 m-0'>
                  <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Team Id</div>
                  <div className='h5 mb-0 font-weight-bold text-gray-800'>{reportData?.data?.team_id ? reportData?.data?.team_id : "N/A"}</div>
                </div>
                <div className='col-md-4 m-0'>
                  <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Name</div>
                  <div className='h5 mb-0 font-weight-bold text-gray-800'>{reportData?.data?.team_name ? reportData?.data?.team_name : "N/A"}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xl-3 col-md-6 mb-4'>
            <div className='card border-top-users-active-in-chalenge-primary shadow h-100 py-2'>
              <div className='col-auto justify-content-center'>
                <div className='d-flex flex-column justify-content-center'>
                  <span className='fa-stack fa-lg align-self-center mb-2'>
                    <i className='fa fa-circle fa-stack-2x' style={{ color: "#7a3ec3" }}></i>
                    <i className='fa fa-tasks fa-stack-1x fa-inverse'></i>
                  </span>
                  <p className='align-self-center font-weight-bold'>PER GOAL ACHIEVE</p>
                </div>
              </div>
              <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                <div className='col m-1'>
                  <div className='h5 mb-0 font-weight-bold text-gray-800 text-center'>
                    {reportData?.data?.per_goal_achieve ? reportData?.data?.per_goal_achieve : "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-3 col-md-6 mb-4'>
            <div className='card border-top-users-one shadow h-100 py-2'>
              <div className='col-auto justify-content-center'>
                <div className='d-flex flex-column justify-content-center'>
                  <span className='fa-stack fa-lg align-self-center mb-2'>
                    <i className='fa fa-circle fa-stack-2x' style={{ color: "#f36a26" }}></i>
                    <i className='fa fa-users fa-stack-1x fa-inverse'></i>
                  </span>
                  <p className='align-self-center font-weight-bold'>TOTAL TEAM STAND</p>
                </div>
              </div>
              <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                <div className='col m-1'>
                  <div className='h5 mb-0 font-weight-bold text-gray-800 text-center'>
                    {reportData?.data?.total_team_stand ? reportData?.data?.total_team_stand : "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-3 col-md-6 mb-4'>
            <div className='card border-top-users-active-in-chalenge-primary shadow h-100 py-2'>
              <div className='col-auto justify-content-center'>
                <div className='d-flex flex-column justify-content-center'>
                  <span className='fa-stack fa-lg align-self-center mb-2'>
                    <i className='fa fa-circle fa-stack-2x' style={{ color: "#7a3ec3" }}></i>
                    <i className='fa fa-tasks fa-stack-1x fa-inverse'></i>
                  </span>
                  <p className='align-self-center font-weight-bold'>TOTAL TEAM STAND TIME</p>
                </div>
              </div>
              <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                <div className='col m-1'>
                  <div className='h5 mb-0 font-weight-bold text-gray-800 text-center'>
                    {reportData?.data?.total_team_stand_time ? reportData?.data?.total_team_stand_time : "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-3 col-md-6 mb-4'>
            <div className='card border-top-users-one shadow h-100 py-2'>
              <div className='col-auto justify-content-center'>
                <div className='d-flex flex-column justify-content-center'>
                  <span className='fa-stack fa-lg align-self-center mb-2'>
                    <i className='fa fa-circle fa-stack-2x' style={{ color: "#f36a26" }}></i>
                    <i className='fa fa-users fa-stack-1x fa-inverse'></i>
                  </span>
                  <p className='align-self-center font-weight-bold'>TOTAL USER</p>
                </div>
              </div>
              <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                <div className='col m-1'>
                  <div className='h5 mb-0 font-weight-bold text-gray-800 text-center'>
                    {reportData?.data?.total_user ? reportData?.data?.total_user : "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Bottom Row - Tables */}
        <div className='card shadow border-top-users-primary'>
          <div className='card-body'>
            <h4 className='text-center'>Percentage of Team User</h4>
            <div className='row'>
              <DataTable
                columns={columnsPercentageTeamUser}
                data={reportData?.data?.per_team_user}
                progressPending={reportData?.loading}
                // pagination
                // paginationServer
                // paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                // onChangePage={handlePageChange}
              />
            </div>
          </div>
        </div>
        <div className='card shadow border-top-users-daily-goal-primary'>
          <div className='card-body'>
            <h4 className='text-center'>Top Standers</h4>
            <div className='row'>
              <DataTable
                columns={columnsTopStander}
                data={reportData?.data?.top_stander}
                progressPending={reportData?.loading}
                // pagination
                // paginationServer
                // paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                // onChangePage={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepartmentReport;
