import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function CharityCreate() {
  const Navigate = useNavigate();
  const [error, setError] = useState([]);
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });
  const [success, setSuccess] = useState("");
  const [successStyle, setSuccessStyle] = useState({
    color: "white",
    display: "none",
  });

  const [charityName, setCharityName] = useState("");
  const [charityDesc, setCharityDesc] = useState();
  const [charityPic, setCharityPic] = useState();

  const handleOnChange = (e) => {
    setCharityPic(e.target.files[0]);
  };

  const createNewCharity = (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("name", charityName);
    formData.append("description", charityDesc);
    formData.append("image", charityPic);

    axios
      .post(process.env.REACT_APP_API_URL + `V1/charity/create`, formData, {
        headers: {
          // Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("_token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
         
        setSuccess(response.data.message + ", redirecting to listing...");
        setErrorStyle({
          color: "red",
          display: "none",
        });
        setSuccessStyle({
          color: "black",
          display: "block",
        });

        setTimeout(() => {
          Navigate("/charity/all");
        }, 2000);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setErrorStyle({
          color: "red",
          display: "block",
        });
        setSuccessStyle({
          color: "black",
          display: "none",
        });
        
        console.log(error);
      });
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Create Charity</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link to="/charity/all">Charity</Link>
                </li>
                <li className="breadcrumb-item active">Create Charity</li>
              </ol>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card card-primary">
                <div className="card-body" style={errorStyle}>
                  <h6>Errors</h6>
                  <ul>
                    {(() => {
                      if (error.name) {
                        return <li>{error.name}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.description) {
                        return <li>{error.description}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.image) {
                        return <li>{error.image}</li>;
                      }
                    })()}
                  </ul>
                </div>
                <div className="card-body" style={successStyle}>
                  <h6>Success</h6>
                  <ul>
                    {(() => {
                      if (success) {
                        return <li>{success}</li>;
                      }
                    })()}
                  </ul>
                </div>
                <form onSubmit={createNewCharity}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="exampleInputName">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName"
                        placeholder="Enter Name"
                        value={charityName}
                        onChange={(e) => setCharityName(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputDescription">
                        Description
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="exampleInputDescription"
                        placeholder="Enter Description"
                        value={charityDesc}
                        onChange={(e) => setCharityDesc(e.target.value)}
                        rows={5}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputImage">Image</label>
                      <input
                        type={"file"}
                        id={"inputImage"}
                        onChange={handleOnChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
