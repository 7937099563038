import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

export default function MilestonesCreate() {
  const [success, setSuccess] = useState("");
  const [errors, setErrors] = useState("");
  const [error, setError] = useState([]);
  const [successStyle, setSuccessStyle] = useState({
    color: "black",
    display: "none",
  });
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });

  const [milestoneType, setMilestoneType] = useState("");
  const [milestoneLabel, setMilestoneLabel] = useState("");
  const [milestoneAward, setMilestoneAward] = useState("");
  const [milestoneDescription, setMilestoneDescription] = useState("");
  const [milestonePic, setMilestonePic] = useState("");
  const [milestoneCategory, setMilestoneCategory] = useState();

  const [searchParams, setSearchParams] = useSearchParams();
  const [imageStyle, setImageStyle] = useState({
    display: "none",
  });
  const [image, setImage] = useState("");
  const Navigate = useNavigate();

 

  const handleOnChange = (e) => {
    setMilestonePic(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]));
    setImageStyle({
      display: "block",
    });
  };

  const createMilestone = (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("category", milestoneCategory);
    formData.append("type", milestoneType);
    formData.append("label", milestoneLabel);
    formData.append("award", milestoneAward);
    formData.append("description", milestoneDescription);
    formData.append("image", milestonePic);

    const params = {
      category: milestoneCategory,
      type: milestoneType,
      label: milestoneLabel,
      award: milestoneAward,
      description: milestoneDescription,
    };

    axios
      .post(process.env.REACT_APP_API_URL + `V1/milestones/create`, formData, {
        headers: {
          // Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("_token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if(response.data.success) {
          setSuccess(response.data.message + ", redirecting to listing...");
          setErrorStyle({
            color: "red",
            display: "none",
          });
          setSuccessStyle({
            color: "black",
            display: "block",
          });
  
          setTimeout(() => {
            Navigate("/milestones/all");
          }, 2000);
        }
        else {
          const list = document.getElementById("error-ul");
          if (list.hasChildNodes()) {
            list.removeChild(list.children[0]);
          }

          setErrors(response.data.message);
          setErrorStyle({
            color: "red",
            display: "block",
          });
        }
        
      })
      .catch((error) => {
        setError(error.response.data.message);
        setErrorStyle({
          color: "red",
          display: "block",
        });
        setSuccessStyle({
          color: "black",
          display: "none",
        });

        console.log(error);
      });
  };

  const handleChangeMilestoneType = (e) => {
    setMilestoneType(e.target.value);
    var type = '';
    if(e.target.value === 'join' || e.target.value === 'finish') {
      type = 'CHALLENGE';
    }
    else if(e.target.value === 'read' || e.target.value === 'watch') 
    {
      type = 'ACTIVITY';
    }
    else if(e.target.value === 'stand') 
    {
      type = 'STAND';
    }
    else if(e.target.value === 'stand_cause' || e.target.value === 'donation_earned' || e.target.value === 'fundraised') 
    {
      type = 'CHARITY';
    }
    else if(e.target.value === 'invite' || e.target.value === 'share') 
    {
      type = 'SOCIAL';
    }
     
    setMilestoneCategory(type);
  };

  const handleChangeMilestoneCategory = (e) => {
    setMilestoneCategory(e.target.value);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Create Milestone</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/milestones/all">Milestone</Link>
                  </li>
                  <li className="breadcrumb-item active">Milestone</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card card-primary">
                  <div className="card-body" style={errorStyle}>
                    <h6>Errors</h6>
                    <ul id="error-ul">
                      {(() => {
                        if (error.type) {
                          return <li>{error.type}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.category) {
                          return <li>{error.category}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.label) {
                          return <li>{error.label}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.award) {
                          return <li>{error.award}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.description) {
                          return <li>{error.description}</li>;
                        }
                      })()}
                       {(() => {
                        if (errors) {
                          return <li>{errors}</li>;
                        }
                      })()}
                    </ul>
                  </div>
                  <div className="card-body" style={successStyle}>
                    {success}
                  </div>
                  <form onSubmit={createMilestone}>
                    <input
                      type="hidden"
                      name="id"
                      value={searchParams.get("id")}
                    />
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="inputTypeA">Type</label>
                        <select
                          className="form-control"
                          id="inputType"
                          onChange={handleChangeMilestoneType}
                        >
                          {" "}
                          <option value="">Select Type</option>
                          <option value="join">Join</option>
                          <option value="finish">Finish</option>
                          <option value="read">Read</option>
                          <option value="watch">Watch</option>
                          <option value="stand">Stand</option>
                          <option value="stand_cause">Stand cause</option>
                          <option value="donation_earned">
                            Donation Earned
                          </option>
                          <option value="fundraised">Fundraised</option>
                          <option value="invite">Invite</option>
                          <option value="share">Share</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputTypeA">Category</label>
                        <select
                          className="form-control"
                          id="inputType"
                          onChange={handleChangeMilestoneCategory}
                          value={milestoneCategory}
                          disabled
                        >
                          {" "}
                          <option value="">Select Type</option>
                          <option value="CHALLENGE">Challenge</option>
                          <option value="ACTIVITY">Activity</option>
                          <option value="STAND">Stand</option>
                          <option value="CHARITY">Charity</option>
                          <option value="SOCIAL">Social</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputLabel">Label</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputLabel"
                          placeholder="Enter Label"
                          value={milestoneLabel}
                          onChange={(e) => setMilestoneLabel(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputAward">Award</label>
                        <input
                          type="number"
                          className="form-control"
                          id="inputAward"
                          placeholder="Enter Award"
                          value={milestoneAward}
                          onChange={(e) => setMilestoneAward(e.target.value)}
                        />
                      </div>{" "}
                      <div className="form-group">
                        <label htmlFor="inputDescription">Description</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputDescription"
                          placeholder="Enter Award"
                          value={milestoneDescription}
                          onChange={(e) =>
                            setMilestoneDescription(e.target.value)
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="milestoneImage">Image</label>
                        <input
                          type={"file"}
                          id={"milestoneImage"}
                          onChange={handleOnChange}
                          className="form-control"
                        />
                        <img alt="" src={image} width="10%" height="10%" style={imageStyle} />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
