import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    logoutUser();
  }, []);

  const logoutUser = async () => {
    const params = {
      _token: localStorage.getItem("_token"),
    };
    const options = {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("_token"),
      }),
    };
    await fetch(process.env.REACT_APP_API_URL + "V1/logout", options)
      .then((response) => response.json())
      .then((response) => {
        // Do something with response.
        
        if (response.success) {
          localStorage.clear();
          navigate("/login");
        } else {
          localStorage.clear(); 
          navigate("/login");
        }
      });
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">Logging you out</div>
          </div>
        </div>
      </div>
    </div>
  );
}
