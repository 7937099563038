import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

export default function DepartmentEdit() {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState([]);
  const [successStyle, setSuccessStyle] = useState({
    color: "black",
    display: "none",
  });
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });
  
  const [departmentName, setDepartmentName] = useState("");
  const [departmentDescription, setDepartmentDescription] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const Navigate = useNavigate();
  const params = {
    id: searchParams.get("id"),
  };
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };
  const getDepartment = async () => {
    try {
      const response = await axios
        .post(
          process.env.REACT_APP_API_URL + "V1/departments/fetch",
          params,
          config
        )
        .then((response) => response.data)
        .catch((err) => console.error(err));
     
      setDepartmentName(response.data.name);
      setDepartmentDescription(response.data.description);
    } catch (err) {
      console.log(err);
    }
  };

  const updateDepartment = (e) => {
    e.preventDefault();

    const params = {
      id: searchParams.get("id"),
      name: departmentName,
      description: departmentDescription,
      corporate_id: localStorage.getItem("corporateId"),
    };

    axios
      .post(process.env.REACT_APP_API_URL + `V1/departments/update`, params, {
        headers: {
          // Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("_token"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        
        setSuccess(response.data.message + ", redirecting to listing...");
        setErrorStyle({
          color: "red",
          display: "none",
        });
        setSuccessStyle({
          color: "black",
          display: "block",
        });

        setTimeout(() => {
          Navigate("/departments/all");
        }, 2000);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setErrorStyle({
          color: "red",
          display: "block",
        });
        setSuccessStyle({
          color: "black",
          display: "none",
        });
        
        console.log(error);
      });
  };

  useEffect(() => {
    getDepartment();
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Edit Department</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/departments/all">Department</Link>
                  </li>
                  <li className="breadcrumb-item active">Edit Department</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card card-primary">
                  <div className="card-body" style={errorStyle}>
                    <h6>Errors</h6>
                    <ul>
                    {(() => {
                      if (error.name) {
                        return <li>{error.name}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.description) {
                        return <li>{error.description}</li>;
                      }
                    })()} 
                  </ul>
                  </div>
                  <div className="card-body" style={successStyle}>
                    {success}
                  </div>
                  <form onSubmit={updateDepartment}>
                    <input
                      type="hidden"
                      name="id"
                      value={searchParams.get("id")}
                    />
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="inputType">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputName"
                          placeholder="Enter Name"
                          value={departmentName}
                          onChange={(e) => setDepartmentName(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputDescription">Description</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputDescription"
                          placeholder="Enter Description"
                          value={departmentDescription}
                          onChange={(e) =>
                            setDepartmentDescription(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
