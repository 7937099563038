import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";

export default function DepartmentList() {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };

  const navigate = useNavigate();
  ////////////////////////////////
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [message, setMessage] = useState("");

  const columns = [
    {
      id: "id",
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      id: "name",
      name: "name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      id: "description",
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      cell: (row) => (
        <>
          <Link to={`/departments/edit?id=${row.id}`} className='btn btn-info btn-sm btn-space-custom'>
            Edit
          </Link>
          <Link
            className='btn btn-danger btn-sm btn-space-custom'
            title='Delete'
            onClick={() => {
              if (window.confirm("Are you sure to delete this Department? Action cannot be reversed.")) {
                handleDelete(row.id);
              }
            }}>
            Delete
          </Link>{" "}
          <Link to={`/department-report/${row?.id}`} className='btn btn-primary btn-sm btn-space-custom'>
            View Report
          </Link>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      // button: true,
    },
  ];

  const fetchDepartments = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + `V1/departments/all?page=${page}&per_page=${perPage}&delay=1`, config);

      setData(response.data.data);
      setTotalRows(response.data.total);
      setLoading(false);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        console.error("API error:", err.message);
      }
    }
  };

  const handlePageChange = (page) => {
    fetchDepartments(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const response = await axios.get(process.env.REACT_APP_API_URL + `V1/departments/all?page=${page}&per_page=${newPerPage}&delay=1`, config);

    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const handleSearcFilter = async (search, newPerPage, page) => {
    setLoading(true);

    const response = await axios.get(process.env.REACT_APP_API_URL + `V1/departments/all?page=1&per_page=10&delay=1&search=${search}`, config);

    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const handleDelete = async (id) => {
    const params = {
      id: id,
    };

    await axios.post(process.env.REACT_APP_API_URL + `V1/departments/delete`, params, config).then((response) => {
      setMessage(
        <>
          <div>
            <div className='alert alert-danger-custom'>{response.data.message}</div>
          </div>
        </>
      );

      const elementToHide = document.getElementById("row-" + id);
      elementToHide.style.display = "none";

      setTimeout(() => {
        setMessage("");
      }, 2000);
    });
  };

  useEffect(() => {
    fetchDepartments(1); // fetch page 1 of departments
  }, []);
  ////////////////////////////////
  return (
    <>
      <div className='content-wrapper'>
        <div className='content-header'>
          <div className='container-fluid'>
            <div className='row mb-2'>
              <div className='col-sm-6'>
                <h1 className='m-0'>Departments</h1>
              </div>
              <div className='col-sm-6'>
                <ol className='breadcrumb float-sm-right'>
                  <li className='breadcrumb-item'>
                    <a href='/#'>Home</a>
                  </li>
                  <li className='breadcrumb-item active'>Departments</li>
                </ol>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 mb-2 my-0'>
                <Link to='/departments/create'>
                  <button className='btn btn-primary'>Add New Department</button>
                </Link>
              </div>
            </div>
            <div className='card'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-12'>{message}</div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='row'>
                      <div className='col-md-2'>
                        <div className='form-group'>
                          <input type='text' placeholder='Search' onChange={(e) => handleSearcFilter(e.target.value)} className='form-control' />
                        </div>
                      </div>
                    </div>
                    <DataTable
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      // defaultSortFieldId="id"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </>
  );
}
