import "./App.css";
import React, { useEffect } from "react";
import { Header } from "./menu/Header";
import Logout from "./components/Logout";
import { Sidebar } from "./menu/Sidebar";
import { Footer } from "./footer/Footer";
import { Login } from "./components/Login";
import { Content } from "./content/Content";
import Datafun from "./pages/Experiments/Datafun";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

//Admin Files
import Admins from "./pages/Users/Admin/Admins";
import AdminCreate from "./pages/Users/Admin/AdminCreate";
import AdminEdit from "./pages/Users/Admin/AdminEdit";
import AdminImport from "./pages/Users/Admin/AdminImport";

//Corporate
import CorporateList from "./pages/Corporates/CorporateList";
import CorporateCreate from "./pages/Corporates/CorporateCreate";
import CorporateEdit from "./pages/Corporates/CorporateEdit";

//Corporate Admins
import CorporateAdmin from "./pages/Users/CorporateAdmin/CorporateAdmin";
import CorporateAdminCreate from "./pages/Users/CorporateAdmin/CorporateAdminCreate";
import CorporateAdminEdit from "./pages/Users/CorporateAdmin/CorporateAdminEdit";

//Corporate Users
import CorporateUsers from "./pages/Users/CorporateUser/CorporateUsers";
import CorporateUserCreate from "./pages/Users/CorporateUser/CorporateUserCreate";
import CorporateUserEdit from "./pages/Users/CorporateUser/CorporateUserEdit";
import CorporateUserImport from "./pages/Users/CorporateUser/CorporateUserImport";

//Individual Users
import IndividualUser from "./pages/Users/IndividualUser/IndividualUser";
import IndividualUserCreate from "./pages/Users/IndividualUser/IndividualUserCreate";
import IndividualUserEdit from "./pages/Users/IndividualUser/IndividualUserEdit";
import IndividualUserImport from "./pages/Users/IndividualUser/IndividualUserImport";

// Challenges
import ChallengesList from "./pages/Challenges/ChallengesList";
import ChallengeCreate from "./pages/Challenges/ChallengeCreate";
import ChallengeEdit from "./pages/Challenges/ChallengeEdit";
import ChallengeDetail from "./pages/Challenges/ChallengeDetail";
import ChallengeCompanyInvite from "./pages/Challenges/ChallengeCompanyInvite";

// Category
import CategoryList from "./pages/Category/CategoryList";
import CategoryCreate from "./pages/Category/CategoryCreate";
import CategoryEdit from "./pages/Category/CategoryEdit";

// Charity
import CharityList from "./pages/Charity/CharityList";
import CharityCreate from "./pages/Charity/CharityCreate";
import CharityEdit from "./pages/Charity/CharityEdit";
import CorporateAdminImport from "./pages/Users/CorporateAdmin/CorporateAdminImport";
import CharityDetail from "./pages/Charity/CharityDetail";
import Profile from "./pages/Users/UserProfile/Profile";
import Forgot from "./components/Forgot";
import SetPassword from "./components/SetPassword";
import BlogList from "./pages/Blog/BlogList";
import BlogCreate from "./pages/Blog/BlogCreate";
import BlogEdit from "./pages/Blog/BlogEdit";
import BlogDetail from "./pages/Blog/BlogDetail";
import AnnouncementList from "./pages/Announcement/AnnouncementList";
import AnnouncementCreate from "./pages/Announcement/AnnouncementCreate";
import AnnouncementEdit from "./pages/Announcement/AnnouncementEdit";
import MilestonesList from "./pages/Milestones/MilestonesList";
import MilestonesEdit from "./pages/Milestones/MilestonesEdit";
import MilestonesCreate from "./pages/Milestones/MilestonesCreate";
import StreaksList from "./pages/Streaks/StreaksList";
import StreaksCreate from "./pages/Streaks/StreaksCreate";
import StreaksEdit from "./pages/Streaks/StreaksEdit";
import DepartmentList from "./pages/Department/DepartmentList";
import DepartmentCreate from "./pages/Department/DepartmentCreate";
import DepartmentEdit from "./pages/Department/DepartmentEdit";
//badges
import BadgesList from "./pages/Badges/BadgesList";
import BadgesCreate from "./pages/Badges/BadgesCreate";
import BadgesEdit from "./pages/Badges/BadgesEdit";
import TnCEdit from "./pages/TermAndConditions/TnCEdit";

import VerifyOtp from "./components/VerifyOtp";
import VerifyOtpTwo from "./components/VerifyOtpTwo";

// Reports
import Reports from "./pages/Reports/Reports";
import ChallengeReport from "./pages/Reports/Challenges/ChallengeReport";
import OverallChallengeReport from "./pages/Reports/Challenges/OverAllChallengeReport";
import CorporateUserReport from "./pages/Reports/Users/CorporateUserReport";
import DepartmentReport from "./pages/Reports/Departments/DepartmentReport";
import AchievementsReport from "./pages/Reports/Challenges/AchievementsReport";
import CorporationReport from "./pages/Reports/CorporationReport/CorporationReport";

// Reports
import BillingList from "./pages/Billing/BillingList";

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem("_token")) {
      navigate("/login");
      if (location.pathname == "/login") {
        navigate("/login");
      } else if (location.pathname == "/reset-password") {
        navigate("/reset-password");
      } else if (location.pathname == "/set-password") {
        navigate("/set-password");
      }
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    
    if (location.pathname == "/login") {
      document.body.className = "login-page";
      document.body.style.minHeight = "500px";
    } else if (location.pathname == "/reset-password") {
      document.body.className = "login-page";
      document.body.style.minHeight = "500px";
    } else if (location.pathname == "/set-password") {
      document.body.className = "login-page";
      document.body.style.minHeight = "500px";
    } else if (location.pathname == "/verify-otp-step-one") {
      document.body.className = "login-page";
      document.body.style.minHeight = "500px";
    } else if (location.pathname == "/verify-otp-step-two") {
      document.body.className = "login-page";
      document.body.style.minHeight = "500px";
    } else {
      document.body.className = "sidebar-mini layout-fixed";
    }
  }, [location.pathname]);

  if (localStorage.getItem("_token")) {
    return (
      <div className='wrapper'>
        <Header />
        <Sidebar />
        <Routes>
          <Route path='/' element={<Content />} />
          <Route path='/logout' element={<Logout />} />
          <Route path='/login' element={<Login />} />
          <Route path='/experiments' element={<Datafun />} />
          <Route path='/profile' element={<Profile />} />

          {/* Admin  */}
          <Route path='/admins' element={<Admins />} />
          <Route path='/admin/create' element={<AdminCreate />} />
          <Route path='/admin/edit' element={<AdminEdit />} />
          <Route path='/admin/import' element={<AdminImport />} />

          {/* Corporate  */}
          <Route path='/corporations' element={<CorporateList />} />
          <Route path='/corporations/create' element={<CorporateCreate />} />
          <Route path='/corporations/edit' element={<CorporateEdit />} />

          {/* Corporate Users */}
          <Route path='/corporate-users' element={<CorporateUsers />} />
          <Route path='/corporate-user/create' element={<CorporateUserCreate />} />
          <Route path='/corporate-user/edit' element={<CorporateUserEdit />} />

          {/* Corporate Admin */}
          <Route path='/corporate-admin' element={<CorporateAdmin />} />
          <Route path='/corporate-admin/create' element={<CorporateAdminCreate />} />
          <Route path='/corporate-admin/edit' element={<CorporateAdminEdit />} />
          <Route path='/corporate-admin/import' element={<CorporateAdminImport />} />

          {/* Individual Users */}
          <Route path='/individual-users' element={<IndividualUser />} />
          <Route path='/individual-user/edit' element={<IndividualUserEdit />} />
          <Route path='/individual-user/create' element={<IndividualUserCreate />} />
          <Route path='/individual-user/import' element={<IndividualUserImport />} />
          <Route path='/corporate-user/import' element={<CorporateUserImport />} />

          {/* Challenges */}
          <Route path='/challenges' element={<ChallengesList />} />
          <Route path='/challenge/create' element={<ChallengeCreate />} />
          <Route path='/challenge/edit' element={<ChallengeEdit />} />
          <Route path='/challenge/view' element={<ChallengeDetail />} />
          <Route path='/challenge/company-invite' element={<ChallengeCompanyInvite />} />

          {/* Category */}
          <Route path='/category/all' element={<CategoryList />} />
          <Route path='/category/create' element={<CategoryCreate />} />
          <Route path='/category/edit' element={<CategoryEdit />} />

          {/* Charity */}
          <Route path='/charity/all' element={<CharityList />} />
          <Route path='/charity/create' element={<CharityCreate />} />
          <Route path='/charity/edit' element={<CharityEdit />} />
          <Route path='/charity/view' element={<CharityDetail />} />

          {/* Blog */}
          <Route path='/blog/all' element={<BlogList />} />
          <Route path='/blog/create' element={<BlogCreate />} />
          <Route path='/blog/edit' element={<BlogEdit />} />
          <Route path='/blog/view' element={<BlogDetail />} />

          {/* Announcement */}
          <Route path='/announcement/all' element={<AnnouncementList />} />
          <Route path='/announcement/create' element={<AnnouncementCreate />} />
          <Route path='/announcement/edit' element={<AnnouncementEdit />} />
          <Route path='/announcement/view' element={<BlogDetail />} />

          {/* Milestones */}
          <Route path='/milestones/all' element={<MilestonesList />} />
          <Route path='/milestones/create' element={<MilestonesCreate />} />
          <Route path='/milestones/edit' element={<MilestonesEdit />} />

          {/* Streaks */}
          <Route path='/streaks/all' element={<StreaksList />} />
          <Route path='/streaks/create' element={<StreaksCreate />} />
          <Route path='/streaks/edit' element={<StreaksEdit />} />

          {/* Badges */}
          <Route path='/badges/all' element={<BadgesList />} />
          <Route path='/badges/create' element={<BadgesCreate />} />
          <Route path='/badges/edit' element={<BadgesEdit />} />

          {/* Departments */}
          <Route path='/departments/all' element={<DepartmentList />} />
          <Route path='/departments/create' element={<DepartmentCreate />} />
          <Route path='/departments/edit' element={<DepartmentEdit />} />

          {/* Term and Condistions */}
          <Route path='/term-and-conditions' element={<TnCEdit />} />

          {/* Reports */}
          <Route path='/reports' element={<Reports />} />
          <Route path='/corporate-report/:corporateId' element={<CorporationReport />} />
          <Route path='/challenge-report/:challengeId' element={<ChallengeReport />} />
          <Route path='/challenge-achievement-report/:challengeId' element={<AchievementsReport />} />
          <Route path='/overall-challenge-report/:challengeId' element={<OverallChallengeReport />} />
          <Route path='/department-report/:departmentId' element={<DepartmentReport />} />
          <Route path='/user-report/:corporateUserId' element={<CorporateUserReport />} />

          {/* Badges */}
          <Route path='/billing/all' element={<BillingList />} />

          {/* password set links */}
          <Route path='/verify-otp-step-one' element={<VerifyOtp />} />
          <Route path='/verify-otp-step-two' element={<VerifyOtpTwo />} />
        </Routes>
        <Footer />
      </div>
    );
  } else {
    // return location.pathname;

    if (location.pathname == "/login") {
      return <Login />;
    } else if (location.pathname == "/reset-password") {
      return <Forgot />;
    } else if (location.pathname == "/verify-otp-step-one") {
      return <VerifyOtp />;
    } else if (location.pathname == "/verify-otp-step-two") {
      return <VerifyOtpTwo />;
    } else if (location.pathname == "/set-password") {
      return <SetPassword token='qwerty' />;
    }
  }
}

export default App;
