import React from "react";
import Chart from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import "./DoughnutChart.css";

const DoughnutChart = ({ dataset }) => {
  const setDoghnutArray = () => {
    if (dataset !== undefined) {
      return checkALlZeros(dataset) ? [1, 1, 1, 1] : dataset;
    }
  };

  const checkALlZeros = (obj) => {
    return Object.keys(obj).every((key) => obj[key] === 0 || obj[key] === false || obj[key] === undefined);
  };

  const data = {
    labels: false,
    datasets: [
      {
        borderColor: "white",
        backgroundColor: ["#56a86c", "#e36262", "#faa346", "#2374ba"],
        data: setDoghnutArray(),
      },
    ],
  };

  const options = {
    cutout: 20,
    responsive: true,
    maintainAspectRatio: true,
    devicePixelRatio: 4,
    hover: {
      mode: "label",
    },
    plugins: {
      legend: {
        display: false,
      },
      // tooltip: {
      //   callbacks: {
      //     title: (xDatapoint) => {
      //       // return toolTipX(xDatapoint);
      //       return "1";
      //     },
      //     label: (yDatapoint) => {
      //       // return toolTipY(yDatapoint);
      //       return "2";
      //     },
      //   },
      // },
    },
  };
  return <Doughnut className='dougnut-chart' data={data} options={options} />;
};

export default DoughnutChart;
