import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getIndividualChallengeAchievementsReport } from "../../../Api/EndpointCalls/EndpointCalls";
import DemoProfile from "../../../Assets/Images/demo_profile.jpg";
import { downloadAsPDF } from "../../../Utils/HelperFunctions";

const AchievementsReport = () => {
  const [reportData, setReportData] = useState({ data: null, loading: true });
  const params = useParams();

  const fetchAchievementReport = async () => {
    try {
      const res = await getIndividualChallengeAchievementsReport(params?.challengeId);
      if (res?.status === 200) {
        setReportData({ data: res?.data, loading: false });
      }
    } catch (error) {
      setReportData({ data: null, loading: false });
    }
  };

  useEffect(() => {
    fetchAchievementReport();
  }, []);

  return (
    <div className='content-wrapper p-2'>
      <div className='pb-2'>
        <div className='col d-flex justify-content-end'>
          <button
            className='download-report-pdf-button btn btn-primary'
            onClick={() => {
              downloadAsPDF(document.getElementById("content-report"));
            }}>
            <div className='row'>
              <i className='fa fa-file-download fa-inverse download-pdf-icon'></i>
              <p className='pe-1 m-0'>Download PDF</p>
            </div>
          </button>
        </div>
      </div>
      <div className='' id='content-report'>
        {/* Top Row - Cards */}
        <h4 className='text-center'>Achievement Report</h4>
        <div className='col'>
          <div className='card shadow border-top-users-active-in-chalenge-primary'>
            <div className='card-body'>
              <div className='col-md-14'>
                <div className='row no-gutters align-items-center mb-2 p-2'>
                  <div className='col-md-4 m-0'>
                    <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Challenge Id</div>
                    <div className='h5 mb-0 font-weight-bold text-gray-800'>
                      {reportData?.data?.data?.challenge_id ? reportData?.data?.data?.challenge_id : "N/A"}
                    </div>
                  </div>
                  <div className='col-md-4 m-0'>
                    <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Name</div>
                    <div className='h5 mb-0 font-weight-bold text-gray-800'>
                      {reportData?.data?.data?.challenge_name ? reportData?.data?.data?.challenge_name : "N/A"}
                    </div>
                  </div>
                  {/* <div className='col-md-4 m-0'>
                    <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Stand Time</div>
                    <div className='h5 mb-0 font-weight-bold text-gray-800'>{"as"}</div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            {/* Rank 1 Card */}
            <div className='col-xl-4 col-md-4 mb-4 main-card'>
              <div className='card shadow h-100 pb-2'>
                <div className='row no-gutters d-flex justify-content-around mb-2 p-2 rank-one-header'>
                  <div className='col-4'>
                    <div className='row'>
                      <div className='col-4'>
                        <span className='fa-stack fa-lg align-self-center'>
                          <i className='fa fa-circle fa-stack-2x' style={{ color: "#f36a26" }}></i>
                          <i className='fa fas fa-shoe-prints fa-stack-1x fa-inverse'></i>
                        </span>
                      </div>
                      <div className='col-8 text-xs font-weight-bold text-black text-uppercase mb-1'>
                        Stood
                        <div className='h5 mb-0 font-weight-bold text-gray-800 mt-1'>
                          {reportData?.data?.data?.top_stander?.[0]?.stood ? reportData?.data?.data?.top_stander?.[0]?.stood : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-4 text-center'>
                    <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Rank</div>
                    <div className='h5 mb-0 font-weight-bold text-gray-800'>{"1"}</div>
                  </div>
                  <div className='col-4 text-center'>
                    <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Duration</div>
                    <div className='h5 mb-0 font-weight-bold text-gray-800'>
                      {reportData?.data?.data?.top_stander?.[0]?.duration ? reportData?.data?.data?.top_stander?.[0]?.duration : "N/A"}
                    </div>
                  </div>
                </div>
                {reportData?.data?.data?.top_stander?.[0] ? (
                  <>
                    <div className='col-auto justify-content-center mb-4 mt-4'>
                      <div className='d-flex flex-column justify-content-center'>
                        <span className='align-self-center mb-2'>
                          <div className='rank-card-profile-pic'>
                            <img alt=""
                              src={
                                reportData?.data?.data?.top_stander?.[0]?.user_data?.[0]
                                  ? `${process.env.REACT_APP_IMAGE_URL}storage/${reportData?.data?.data?.top_stander?.[0]?.user_data?.[0]?.profile_pic}`
                                  : DemoProfile
                              }
                            />
                          </div>
                        </span>
                        <p className='align-self-center font-weight-bold h4'>
                          {reportData?.data?.data?.top_stander?.[0]?.user_data?.[0]?.name
                            ? reportData?.data?.data?.top_stander?.[0]?.user_data?.[0]?.name
                            : "N/A"}
                        </p>
                        <p className='align-self-center h5'>
                          User Id:{" "}
                          {reportData?.data?.data?.top_stander?.[0]?.user_data?.[0]?.user_id
                            ? reportData?.data?.data?.top_stander?.[0]?.user_data?.[0]?.user_id
                            : "N/A"}
                        </p>
                        <p className='align-self-center h5'>
                          Goal Percentage:{" "}
                          {reportData?.data?.data?.top_stander?.[0]?.user_data?.[0]?.goal_per
                            ? reportData?.data?.data?.top_stander?.[0]?.user_data?.[0]?.goal_per + "%"
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className='card-rank-list-wrapper'>
                      {reportData?.data?.data?.top_stander?.[0]?.user_data?.slice(1).length > 0 ? (
                        reportData?.data?.data?.top_stander?.[0]?.user_data?.slice(1)?.map((el, i) => {
                          return (
                            <div className='row no-gutters card-rank-list d-flex justify-content-between mb-2 p-2'>
                              <div className='col-2'>
                                <div className='table-profile-pic mt-1 ml-2'>
                                  <img alt="" src={el?.profile_pic ? `${process.env.REACT_APP_IMAGE_URL}storage/${el?.profile_pic}` : DemoProfile} />
                                </div>
                              </div>
                              <div className='col-2'>
                                <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>user id</div>
                                <div className='h5 mb-0 font-weight-bold text-gray-800'>{el?.user_id}</div>
                              </div>
                              <div className='col-4'>
                                <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>name</div>
                                <div className='h5 mb-0 font-weight-bold text-gray-800'>{el?.name}</div>
                              </div>
                              <div className='col-4'>
                                <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Goal percentage</div>
                                <div className='h5 mb-0 font-weight-bold text-gray-800'>{el?.goal_per}%</div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <h5 className='text-center mt-4'>No more records.</h5>
                      )}
                    </div>
                  </>
                ) : (
                  <h5 className='text-center mt-4'>No record found.</h5>
                )}
              </div>
            </div>
            {/* Rank 2 Glance */}
            <div className='col-xl-4 col-md-4 mb-4 main-card'>
              <div className='card shadow h-100 pb-2'>
                <div className='row no-gutters d-flex justify-content-around mb-2 p-2 rank-two-header'>
                  <div className='col-4'>
                    <div className='row'>
                      <div className='col-4'>
                        <span className='fa-stack fa-lg align-self-center'>
                          <i className='fa fa-circle fa-stack-2x' style={{ color: "#4e73df" }}></i>
                          <i className='fa fas fa-shoe-prints fa-stack-1x fa-inverse'></i>
                        </span>
                      </div>
                      <div className='col-8 text-xs font-weight-bold text-black text-uppercase mb-1'>
                        Stood
                        <div className='h5 mb-0 font-weight-bold text-gray-800 mt-1'>
                          {reportData?.data?.data?.top_stander?.[1]?.stood ? reportData?.data?.data?.top_stander?.[1]?.stood : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-4 text-center'>
                    <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Rank</div>
                    <div className='h5 mb-0 font-weight-bold text-gray-800'>{"2"}</div>
                  </div>
                  <div className='col-4 text-center'>
                    <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Duration</div>
                    <div className='h5 mb-0 font-weight-bold text-gray-800'>
                      {reportData?.data?.data?.top_stander?.[1]?.duration ? reportData?.data?.data?.top_stander?.[1]?.duration : "N/A"}
                    </div>
                  </div>
                </div>
                {reportData?.data?.data?.top_stander?.[1] ? (
                  <>
                    <div className='col-auto justify-content-center mb-4 mt-4'>
                      <div className='d-flex flex-column justify-content-center'>
                        <span className='align-self-center mb-2'>
                          <div className='rank-card-profile-pic'>
                            <img alt=""
                              src={
                                reportData?.data?.data?.top_stander?.[1]?.user_data?.[0]
                                  ? `${process.env.REACT_APP_IMAGE_URL}storage/${reportData?.data?.data?.top_stander?.[1]?.user_data?.[0]?.profile_pic}`
                                  : DemoProfile
                              }
                            />
                          </div>
                        </span>
                        <p className='align-self-center font-weight-bold h4'>
                          {reportData?.data?.data?.top_stander?.[1]?.user_data?.[0]?.name
                            ? reportData?.data?.data?.top_stander?.[1]?.user_data?.[0]?.name
                            : "N/A"}
                        </p>
                        <p className='align-self-center h5'>
                          User Id:{" "}
                          {reportData?.data?.data?.top_stander?.[1]?.user_data?.[0]?.user_id
                            ? reportData?.data?.data?.top_stander?.[1]?.user_data?.[0]?.user_id
                            : "N/A"}
                        </p>
                        <p className='align-self-center h5'>
                          Goal Percentage:{" "}
                          {reportData?.data?.data?.top_stander?.[1]?.user_data?.[0]?.goal_per
                            ? reportData?.data?.data?.top_stander?.[1]?.user_data?.[0]?.goal_per + "%"
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className='card-rank-list-wrapper'>
                      {reportData?.data?.data?.top_stander?.[1]?.user_data?.slice(1).length > 0 ? (
                        reportData?.data?.data?.top_stander?.[1]?.user_data?.slice(1)?.map((el, i) => {
                          return (
                            <div className='row no-gutters card-rank-list d-flex justify-content-between mb-2 p-2'>
                              <div className='col-2'>
                                <div className='table-profile-pic mt-1 ml-2'>
                                  <img alt="" src={el?.profile_pic ? `${process.env.REACT_APP_IMAGE_URL}storage/${el?.profile_pic}` : DemoProfile} />
                                </div>
                              </div>
                              <div className='col-2'>
                                <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>user id</div>
                                <div className='h5 mb-0 font-weight-bold text-gray-800'>{el?.user_id}</div>
                              </div>
                              <div className='col-4'>
                                <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>name</div>
                                <div className='h5 mb-0 font-weight-bold text-gray-800'>{el?.name}</div>
                              </div>
                              <div className='col-4'>
                                <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Goal percentage</div>
                                <div className='h5 mb-0 font-weight-bold text-gray-800'>{el?.goal_per}%</div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <h5 className='text-center mt-4'>No more records.</h5>
                      )}
                    </div>
                  </>
                ) : (
                  <h5 className='text-center mt-4'>No record found.</h5>
                )}
              </div>
            </div>
            {/* Rank 3 Card */}
            <div className='col-xl-4 col-md-4 mb-4 main-card'>
              <div className='card shadow h-100 pb-2'>
                <div className='row no-gutters d-flex justify-content-around mb-2 p-2 rank-three-header'>
                  <div className='col-4'>
                    <div className='row'>
                      <div className='col-4'>
                        <span className='fa-stack fa-lg align-self-center'>
                          <i className='fa fa-circle fa-stack-2x' style={{ color: "#e0c85a" }}></i>
                          <i className='fa fas fa-shoe-prints fa-stack-1x fa-inverse'></i>
                        </span>
                      </div>
                      <div className='col-8 text-xs font-weight-bold text-black text-uppercase mb-1'>
                        Stood
                        <div className='h5 mb-0 font-weight-bold text-gray-800 mt-1'>
                          {reportData?.data?.data?.top_stander?.[2]?.stood ? reportData?.data?.data?.top_stander?.[2]?.stood : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-4 text-center'>
                    <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Rank</div>
                    <div className='h5 mb-0 font-weight-bold text-gray-800'>{"3"}</div>
                  </div>
                  <div className='col-4 text-center'>
                    <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Duration</div>
                    <div className='h5 mb-0 font-weight-bold text-gray-800'>
                      {reportData?.data?.data?.top_stander?.[2]?.duration ? reportData?.data?.data?.top_stander?.[2]?.duration : "N/A"}
                    </div>
                  </div>
                </div>
                {reportData?.data?.data?.top_stander?.[2] ? (
                  <>
                    <div className='col-auto justify-content-center mb-4 mt-4'>
                      <div className='d-flex flex-column justify-content-center'>
                        <span className='align-self-center mb-2'>
                          <div className='rank-card-profile-pic'>
                            <img
                              alt='...'
                              src={
                                reportData?.data?.data?.top_stander?.[2]?.user_data?.[0]
                                  ? `${process.env.REACT_APP_IMAGE_URL}storage/${reportData?.data?.data?.top_stander?.[2]?.user_data?.[0]?.profile_pic}`
                                  : DemoProfile
                              }
                            />
                          </div>
                        </span>
                        <p className='align-self-center font-weight-bold h4'>
                          {reportData?.data?.data?.top_stander?.[2]?.user_data?.[0]?.name
                            ? reportData?.data?.data?.top_stander?.[2]?.user_data?.[0]?.name
                            : "N/A"}
                        </p>
                        <p className='align-self-center h5'>
                          User Id:{" "}
                          {reportData?.data?.data?.top_stander?.[2]?.user_data?.[0]?.user_id
                            ? reportData?.data?.data?.top_stander?.[2]?.user_data?.[0]?.user_id
                            : "N/A"}
                        </p>
                        <p className='align-self-center h5'>
                          Goal Percentage:{" "}
                          {reportData?.data?.data?.top_stander?.[2]?.user_data?.[0]?.goal_per
                            ? reportData?.data?.data?.top_stander?.[2]?.user_data?.[0]?.goal_per + "%"
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className='card-rank-list-wrapper'>
                      {reportData?.data?.data?.top_stander?.[2]?.user_data?.slice(1).length > 0 ? (
                        reportData?.data?.data?.top_stander?.[2]?.user_data?.slice(1)?.map((el, i) => {
                          return (
                            <div className='row no-gutters card-rank-list d-flex justify-content-between mb-2 p-2'>
                              <div className='col-2'>
                                <div className='table-profile-pic mt-1 ml-2'>
                                  <img alt="" src={el?.profile_pic ? `${process.env.REACT_APP_IMAGE_URL}storage/${el?.profile_pic}` : DemoProfile} />
                                </div>
                              </div>
                              <div className='col-2'>
                                <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>user id</div>
                                <div className='h5 mb-0 font-weight-bold text-gray-800'>{el?.user_id}</div>
                              </div>
                              <div className='col-4'>
                                <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>name</div>
                                <div className='h5 mb-0 font-weight-bold text-gray-800'>{el?.name}</div>
                              </div>
                              <div className='col-4'>
                                <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Goal percentage</div>
                                <div className='h5 mb-0 font-weight-bold text-gray-800'>{el?.goal_per}%</div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <h5 className='text-center mt-4'>No more records.</h5>
                      )}
                    </div>
                  </>
                ) : (
                  <h5 className='text-center mt-4'>No record found.</h5>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AchievementsReport;
