import axios from "axios";

export const getApiCall = (path) => {
  const token = localStorage.getItem("_token");

  return axios.get(process.env.REACT_APP_API_URL + path, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postApiCall = (path, data) => {
  const token = localStorage.getItem("_token");

  return axios.post(process.env.REACT_APP_API_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
