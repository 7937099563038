import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

export default function StreaksCreate() {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState([]);
  const [successStyle, setSuccessStyle] = useState({
    color: "black",
    display: "none",
  });
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });

  const [streakCategory, setStreakCategory] = useState("");
  const [streakAction, setStreakAction] = useState("");
  const [streakAward, setStreakAward] = useState("");
  const [streakDescription, setStreakDescription] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const Navigate = useNavigate();

  const createStreak = (e) => {
    e.preventDefault();

    e.preventDefault();

    let formData = new FormData();
    formData.append("category", streakCategory);
    formData.append("action", streakAction);
    formData.append("award", streakAward);
    formData.append("description", streakDescription);
    

    axios
      .post(process.env.REACT_APP_API_URL + `V1/streaks/create`, formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("_token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        
        setSuccess(response.data.message + ", redirecting to listing...");
        setErrorStyle({
          color: "red",
          display: "none",
        });
        setSuccessStyle({
          color: "black",
          display: "block",
        });

        setTimeout(() => {
          Navigate("/Streaks/all");
        }, 2000);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setErrorStyle({
          color: "red",
          display: "block",
        });
        setSuccessStyle({
          color: "black",
          display: "none",
        });
        console.log(error);
      });
  };

  const handleChangeStreakCategory = (e) => {
    setStreakCategory(e.target.value);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Create Streak</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/users">Streak</Link>
                  </li>
                  <li className="breadcrumb-item active">Streaks</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card card-primary">
                  <div className="card-body" style={errorStyle}>
                    <h6>Errors</h6>
                    <ul>
                    {(() => {
                      if (error.category) {
                        return <li>{error.category}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.action) {
                        return <li>{error.action}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.award) {
                        return <li>{error.award}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.description) {
                        return <li>{error.description}</li>;
                      }
                    })()}
                  </ul>
                  </div>
                  <div className="card-body" style={successStyle}>
                    {success}
                  </div>
                  <form onSubmit={createStreak}>
                    <input
                      type="hidden"
                      name="id"
                      value={searchParams.get("id")}
                    />
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="inputTypeA">Category</label>
                        <select
                          className="form-control"
                          id="inputType"
                          onChange={handleChangeStreakCategory}
                        >
                          <option value="">Select Category</option>
                          <option value="challenge">Challenge</option>
                          <option value="daily">Daily</option>
                          <option value="weekly">Weekly</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputType">Action</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputAction"
                          placeholder="Enter Action"
                          value={streakAction}
                          onChange={(e) => setStreakAction(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputAward">Award</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputAward"
                          placeholder="Enter Award"
                          value={streakAward}
                          onChange={(e) => setStreakAward(e.target.value)}
                        />
                      </div>{" "}
                      <div className="form-group">
                        <label htmlFor="inputDescription">Description</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputDescription"
                          placeholder="Enter Description"
                          value={streakDescription}
                          onChange={(e) => setStreakDescription(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="streakImage">Image</label>
                        <input
                          type={"file"}
                          id={"streakImage"}
                          onChange=""
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
