import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function CorporateList() {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };

  ////////////////////////////////
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");

  const location = useLocation();

  const typeView = location?.state?.type;

  const navigate = useNavigate();

  const columns = [
    {
      id: "id",
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      id: "name",
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      cell: (row) => (
        <>
          <Link to={`/corporate-admin?id=${row.id}`} className='btn btn-primary btn-sm btn-space-custom'>
            Corporation Admins
          </Link>{" "}
          <Link to={`/corporations/edit?id=${row.id}`} className='btn btn-info btn-sm btn-space-custom'>
            Edit
          </Link>
          {typeView === "sidebar" || typeView === undefined ? (
            ""
          ) : (
            <Link to={typeView === "corporation_report" && `/corporate-report/${row.id}`} className='btn btn-info btn-sm btn-space-custom'>
              Report
            </Link>
          )}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      // button: true,
    },
  ];

  const fetchCorporates = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `V1/corporates/get-all-corporates?type=2&page=${page}&per_page=${perPage}&delay=1`,
        config
      );

      setData(response.data.data);
      setTotalRows(response.data.total);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        console.error("API error:", error.message);
      }
    }
  };

  const handlePageChange = (page) => {
    fetchCorporates(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const response = await axios.get(
      process.env.REACT_APP_API_URL + `V1/corporates/get-all-corporates?type=2&page=${page}&per_page=${newPerPage}&delay=1`,
      config
    );

    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const handleSearcFilter = async (search, newPerPage, page = 1) => {
    setLoading(true);

    const response = await axios.get(
      process.env.REACT_APP_API_URL + `V1/corporates/get-all-corporates?page=${page}&per_page=10&delay=1&search=${search}`,
      config
    );

    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    fetchCorporates(1); // fetch page 1 of users
  }, []);

  return (
    <>
      <div className='content-wrapper'>
        <div className='content-header'>
          <div className='container-fluid'>
            <div className='row mb-2'>
              <div className='col-sm-6'>
                <h1 className='m-0'>Corporations</h1>
              </div>
              <div className='col-sm-6'>
                <ol className='breadcrumb float-sm-right'>
                  <li className='breadcrumb-item'>
                    <a href='/#'>Home</a>
                  </li>
                  <li className='breadcrumb-item active'>Corporations</li>
                </ol>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 mb-2 my-0'>
                <Link to='/corporations/create'>
                  <button className='btn btn-primary'>Add New Corporation</button>
                </Link>
              </div>
            </div>
            <div className='card'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='row'>
                      <div className='col-md-2'>
                        <div className='form-group'>
                          <input type='text' placeholder='Search' onChange={(e) => handleSearcFilter(e.target.value)} className='form-control' />
                        </div>
                      </div>
                    </div>
                    <DataTable
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      // defaultSortFieldId="id"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
