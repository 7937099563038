import axios from "axios";
import React, { useState } from "react";
import { Link,useNavigate } from "react-router-dom";

export default function VerifyOtp() {
  const [error, setError] = useState([]);
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });
  const [errorValidation, setErrorValidation] = useState([]);
  const [errorValidationStyle, setErrorValidationStyle] = useState({
    color: "white",
    display: "none",
  });
 
  const [success, setSuccess] = useState("");
  const [successStyle, setSuccessStyle] = useState({
    color: "white",
    display: "none",
  });
  
  const navigate = useNavigate();

  const [userOTP, setUserOTP] = useState("");
  const [userEmail, setUserEmail] = useState(localStorage.getItem("userEmail"));

  const handleForgotPasswordOTP = (e) => {
    e.preventDefault();

    const params = {
      otp: userOTP,
      email: userEmail,
    };

    

    axios
      .post(process.env.REACT_APP_API_URL + `V1/verify-otp-step-one`, params, {
        headers: {
          // Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("_token"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        
        setSuccess(response.data.message);
        navigate("/verify-otp-step-two"); 
        setErrorStyle({
          color: "red",
          display: "none",
        });
        setErrorValidationStyle({
          color: "red",
          display: "none",
        });
        setSuccessStyle({
          color: "black",
          display: "block",
        });
      })
      .catch((error) => {
        
        if(error.response.data.message) {
          setError(error.response.data.message);
          setErrorValidationStyle({
            color: "red",
            display: "none",
          });
          setErrorStyle({
            color: "red",
            display: "block",
          });
        }
        if(error.response.data.message_validation) {
          setErrorValidation(error.response.data.message_validation);
          setErrorStyle({
            color: "red",
            display: "none",
          });
          setErrorValidationStyle({
            color: "red",
            display: "block",
          });

        }
        
         setSuccessStyle({
          color: "black",
          display: "none",
        });
        
        console.log(error);
      });
  };

  return (
    <>
     <div className="login-box">
        <div className="login-logo">
          <a href="../../index2.html">
            <b>Stand</b>Strong
          </a>
        </div>
      <div className="card">
        <div className="card-body login-card-body">
          <p className="login-box-msg">
            Enter OTP
          </p>
          <form onSubmit={handleForgotPasswordOTP}>
          <div className="input-group mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                value={localStorage.getItem("userEmail")}
                onChange={(e) => setUserEmail(e.target.value)}
                disabled
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-envelope" />
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="OTP"
                onChange={(e) => setUserOTP(e.target.value)}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock" />
                </div>
              </div>
            </div>
           <div className="row">
              <div className="col-12">
                <button type="submit" className="btn btn-primary btn-block">
                  Submit
                </button>
              </div>
              {/* /.col */}
            </div>
          </form>

          <div className="card-body" style={errorStyle}>
               {(() => {
                if (error) {
                  return <li>{error}</li>;
                }
              })()}
          </div>
          <div className="card-body" style={errorValidationStyle}> 
              {(() => {
                if (errorValidation.email) {
                  return <li>{errorValidation.email}</li>;
                }
              })()}
              {(() => {
                if (errorValidation.otp) {
                  return <li>{errorValidation.otp}</li>;
                }
              })()}
          </div>
          <div className="card-body" style={successStyle}>
            {(() => {
              if (success) {
                return <>{success}</>;
              }
            })()}
          </div>

          <p className="mt-3 mb-1">
            <Link to={"/login"}>Login</Link>
          </p>
        </div>
        {/* /.login-card-body */}
      </div>
      </div>
    </>
  );
}
