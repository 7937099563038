import axios from "axios";
import React, { useState } from "react";
import { Link,useNavigate } from "react-router-dom";

export default function VerifyOtpTwo() {
  
  const [error, setError] = useState([]);
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });
  const [errorValidation, setErrorValidation] = useState([]);
  const [errorValidationStyle, setErrorValidationStyle] = useState({
    color: "white",
    display: "none",
  });

  const [success, setSuccess] = useState("");
  const [successStyle, setSuccessStyle] = useState({
    color: "white",
    display: "none",
  });
  
  const navigate = useNavigate();
 
  const [userPassword, setUserPassword] = useState("");
  const [userCPassword, setUserCPassword] = useState("");
  const [userEmail, setUserEmail] = useState(localStorage.getItem("userEmail"));
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState('fa fa-eye-slash');
  const [ntype, setNType] = useState('password');
  const [nicon, setNIcon] = useState('fa fa-eye-slash');
  
  const handleToggle = () => {
     
    if (type==='password'){
       setIcon('fas fa-eye');
       setType('text')
    } else {
       setIcon('fa fa-eye-slash')
       setType('password')
    }
 }
 const handleNToggle = () => {
     
  if (ntype==='password'){
     setNIcon('fas fa-eye');
     setNType('text')
  } else {
     setNIcon('fa fa-eye-slash')
     setNType('password')
  }
}

  const handleForgotPassword = (e) => {
    e.preventDefault();

    const params = {
      email: userEmail,
      password: userPassword,
      c_password: userCPassword,
    };

    axios
      .post(process.env.REACT_APP_API_URL + `V1/verify-otp-step-two`, params, {
        headers: {
          // Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("_token"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        
        setSuccess(response.data.message);
        setTimeout(() => {
          navigate("/login"); 

        }, 3000);
        if(!response.data.success) {
          setErrorStyle({
            color: "red",
            display: "none",
          });
          setErrorValidationStyle({
            color: "red",
            display: "none",
          });
          setSuccessStyle({
            color: "red",
            display: "block",
          });
        }
        else {
          setErrorStyle({
            color: "red",
            display: "none",
          });
          setErrorValidationStyle({
            color: "red",
            display: "none",
          });
          setSuccessStyle({
            color: "green",
            display: "block",
          });
        }
        
        
         
      })
      .catch((error) => {
        if(error.response.data.message) {
          setError(error.response.data.message);
          setErrorValidationStyle({
            color: "red",
            display: "none",
          });
          setErrorStyle({
            color: "red",
            display: "block",
          });
        }
        if(error.response.data.message_validation) {
          setErrorValidation(error.response.data.message_validation);
          setErrorStyle({
            color: "red",
            display: "none",
          });
          setErrorValidationStyle({
            color: "red",
            display: "block",
          });
        }
        
       
        
        setSuccessStyle({
          color: "black",
          display: "none",
        });
        
      });
  };

  return (
    <>
      <div className="card">
        <div className="card-body login-card-body">
          <p className="login-box-msg">
            Enter Password
          </p>
          <form onSubmit={handleForgotPassword}>
          <div className="input-group mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                value={localStorage.getItem("userEmail")}
                onChange={(e) => setUserEmail(e.target.value)}
                disabled
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-envelope" />
                </div>
              </div>
              </div>
          <div className="input-group mb-3">
              <input
                type={type}
                className="form-control"
                placeholder="Password"
                onChange={(e) => setUserPassword(e.target.value)}
              /> 
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className={icon} onClick={handleToggle}/>
                </div>
              </div>
              
            </div>
            <div className="input-group mb-3">
              <input
                type={ntype}
                className="form-control"
                placeholder="Confirm Password"
                onChange={(e) => setUserCPassword(e.target.value)}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className={nicon} onClick={handleNToggle}/>
                </div>
              </div>
            </div>
           <div className="row">
              <div className="col-12">
                <button type="submit" className="btn btn-primary btn-block">
                  Submit
                </button>
              </div>
              {/* /.col */}
            </div>
          </form>

          <div className="card-body" style={errorStyle}>
               {(() => {
                if (error) {
                  return <li>{error}</li>;
                }
              })()}
          </div>
          <div className="card-body" style={errorValidationStyle}> 
              {(() => {
                if (errorValidation.email) {
                  return <li>{errorValidation.email}</li>;
                }
              })()}
              {(() => {
                if (errorValidation.password) {
                  return <li>{errorValidation.password}</li>;
                }
              })()}
          </div>
          <div className="card-body" style={successStyle}>
            {(() => {
              if (success) {
                return <>{success}</>;
              }
            })()}
          </div>

          <p className="mt-3 mb-1">
            <Link to={"/login"}>Login</Link>
          </p>
        </div>
        {/* /.login-card-body */}
      </div>
    </>
  );
}
