import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import "./BarGraph.css";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarGraph = ({ dataset, chartType }) => {
  let teamName = [];
  let graphData = [];
  let toolTipArrayAdd = [];

  if (dataset?.team_avg_stood !== undefined && dataset?.team_avg_stood.length > 0) {
    dataset?.team_avg_stood.forEach((el, i) => {
      return teamName.push(`${el?.team_name}`) && graphData.push(`${el?.team_avg_stood}`);
    });
  }

  if (dataset?.time_length !== undefined && dataset?.time_length.length > 0) {
    dataset?.time_length.forEach((el, i) => {
      return teamName.push(`${el?.stand_time}`) && graphData.push(`${el?.user_percentage}`);
    });
  }

  if (dataset?.time_stood !== undefined && dataset?.time_stood.length > 0) {
    dataset?.time_stood.forEach((el, i) => {
      return teamName.push(`${el?.date}`) && graphData.push(`${el?.user_percentage}`);
    });
  }

  if (dataset?.length > 0 && chartType === "corporateUserReport") {
    dataset?.map((el, i) => {
      return teamName.push(`${el?.date}`) && graphData.push(`${el?.total_stand}`) && toolTipArrayAdd.push(`${el?.total_stand_time}`);
    });
  }

  const xAxisNames = () => {
    if (dataset?.team_avg_stood) {
      return "Teams";
    } else if (dataset?.time_length) {
      return "Minutes";
    } else if (dataset?.time_stood) {
      return "Date";
    } else if (chartType === "corporateUserReport") {
      return "Date";
    }
  };

  const yAxisNames = () => {
    if (dataset?.team_avg_stood) {
      return "Teams Average Stood";
    } else if (dataset?.time_length) {
      return "User Percentage";
    } else if (dataset?.time_stood) {
      return "User Percentage";
    } else if (chartType === "corporateUserReport") {
      return "Total Stood";
    }
  };

  let max = graphData?.length > 0 ? Math.max(...graphData) + 5 : 100;

  const toolTipLabel = (val) => {
    return chartType === "corporateUserReport" ? `Total Stand Time - ${toolTipArrayAdd[val?.dataIndex]} Minutes` : val?.label;
  };

  const toolTipTitle = (val) => {
    return chartType === "corporateUserReport" ? `Total Stood - ${val?.[0]?.formattedValue}` : val?.[0]?.formattedValue;
  };

  const options = {
    responsive: true,
    devicePixelRatio: 4,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (xDatapoint) => {
            return toolTipTitle(xDatapoint);
          },
          label: (yDatapoint) => {
            return toolTipLabel(yDatapoint);
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        steps: 10,
        stepValue: 5,
        max: max,
        title: {
          display: true,
          text: yAxisNames(),
        },
      },
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: xAxisNames(),
        },
      },
    },
  };

  const data = {
    labels: teamName,
    datasets: [
      {
        data: graphData,
        backgroundColor: "#5BC6D6",
      },
    ],
  };

  return <Bar className='bar-graph' options={options} data={data} />;
};

export default BarGraph;
