import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

export default function StreaksEdit() {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState([]);
  const [successStyle, setSuccessStyle] = useState({
    color: "black",
    display: "none",
  });
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });

  const [streakId, setStreakId] = useState("");
  const [streakCategory, setStreakCategory] = useState("");
  const [streakAction, setStreakAction] = useState("");
  const [streakLabel, setStreakLabel] = useState("");
  const [streakAward, setStreakAward] = useState("");
  const [streakDescription, setStreakDescription] = useState("");
  const [streakPic, setStreakPic] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const [image, setImage] = useState("");
  
  const [editImageStyle, setEditImageStyle] = useState({
    display: "block",
  });
  const [imageStyle, setImageStyle] = useState({
    display: "none",
  });


  const Navigate = useNavigate();

  const params = {
    id: searchParams.get("id"),
  };
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };
  const getStreak = async () => {
    try {
      const response = await axios
        .post(
          process.env.REACT_APP_API_URL + "V1/streaks/fetch",
          params,
          config
        )
        .then((response) => response.data)
        .catch((err) => console.error(err));
      setStreakId(response.data.id);
      setStreakCategory(response.data.category);
      setStreakAction(response.data.action);
      setStreakLabel(response.data.label);
      setStreakAward(response.data.award);
      setStreakDescription(response.data.description);
      setStreakPic(response.data.image);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnChange = (e) => {
    setStreakPic(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]));
    setEditImageStyle({
      display: "none",
    });
    setImageStyle({
      display: "block",
    });
  };

  const updateStreak = (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("id", streakId);
    formData.append("category", streakCategory);
    formData.append("action", streakAction);
    formData.append("label", streakLabel);
    formData.append("award", streakAward);
    formData.append("description", streakDescription);
    formData.append("image", streakPic);

    axios
      .post(process.env.REACT_APP_API_URL + `V1/streaks/update`, formData, {
        headers: {
          // Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("_token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setSuccess(response.data.message + ", redirecting to listing...");
        setErrorStyle({
          color: "red",
          display: "none",
        });
        setSuccessStyle({
          color: "black",
          display: "block",
        });

        setTimeout(() => {
          Navigate("/streaks/all");
        }, 2000);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setErrorStyle({
          color: "red",
          display: "block",
        });
        setSuccessStyle({
          color: "black",
          display: "none",
        });
        console.log(error);
      });
  };

  useEffect(() => {
    getStreak();
  }, []);

  const handleChangeStreakCategory = (e) => {
    setStreakCategory(e.target.value);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Edit Streak</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/streaks/all">Streaks</Link>
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card card-primary">
                  <div className="card-body" style={errorStyle}>
                    <h6>Errors</h6>
                    <ul>
                      {(() => {
                        if (error.category) {
                          return <li>{error.category}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.action) {
                          return <li>{error.action}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.label) {
                          return <li>{error.label}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.award) {
                          return <li>{error.award}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.description) {
                          return <li>{error.description}</li>;
                        }
                      })()}
                    </ul>
                  </div>
                  <div className="card-body" style={successStyle}>
                    {success}
                  </div>
                  <form onSubmit={updateStreak}>
                    <input
                      type="hidden"
                      name="id"
                      value={searchParams.get("id")}
                    />
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="inputType">Category</label>
                        <select
                          className="form-control"
                          id="inputType"
                          onChange={handleChangeStreakCategory}
                          value={streakCategory}
                        >
                          <option value="">Select Category</option>
                          <option value="challenge">Challenge</option>
                          <option value="daily">Daily</option>
                          <option value="weekly">Weekly</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputType">Action</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputAction"
                          placeholder="Enter Action"
                          value={streakAction}
                          onChange={(e) => setStreakAction(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputLabel">Label</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputLabel"
                          placeholder="Enter Label"
                          value={streakLabel}
                          onChange={(e) => setStreakLabel(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputAward">Award</label>
                        <input
                          type="number"
                          className="form-control"
                          id="inputAward"
                          placeholder="Enter Award"
                          value={streakAward}
                          onChange={(e) => setStreakAward(e.target.value)}
                        />
                      </div>{" "}
                      <div className="form-group">
                        <label htmlFor="inputDescription">Description</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputDescription"
                          placeholder="Enter Award"
                          value={streakDescription}
                          onChange={(e) => setStreakDescription(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputImage">Image</label>
                        <input
                          type={"file"}
                          id={"exampleInputImage"}
                          onChange={handleOnChange}
                          className="form-control"
                        />

                        {(() => {
                          if (streakPic !== "") {
                            return (
                              <img alt=""
                                src={
                                  process.env.REACT_APP_IMAGE_URL +
                                  "/storage/" +
                                  streakPic
                                }
                                width="10%"
                                height="10%"
                                style={editImageStyle}
                              />
                            );
                          }
                        })()}
                        <img alt="" src={image} width="10%" height="10%" style={imageStyle} />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
