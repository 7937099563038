import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

export default function SetPassword(props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [emailReset, setEmailReset] = useState("");
  const [passwordReset, setPasswordReset] = useState("");
  const [confirmPasswordReset, setConfirmPasswordReset] = useState("");

  const params = {
    email: emailReset,
    token: props.token,
    password: passwordReset,
    c_password: confirmPasswordReset,
  };
  const config = {
    headers: {
      Accept: "application/json",
    },
  };

  
  const setPassword = async (e) => {
    e.preventDefault();

    try {
      await axios
        .post(process.env.REACT_APP_API_URL + "V1/set-password", params, config)
        .then((response) => {
           
        })
        .then((response) => {});
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="card">
        <div className="card-body login-card-body">
          <p className="login-box-msg">
            You are only one step a way from your new password, set your
            password now.
          </p>
          <form onSubmit={setPassword}>
            <input type="hidden" name="token" value={props.token} />
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                value={emailReset}
                onChange={(e) => setEmailReset(e.target.value)}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-envelope" />
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                value={passwordReset}
                onChange={(e) => setPasswordReset(e.target.value)}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock" />
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="Confirm Password"
                value={confirmPasswordReset}
                onChange={(e) => setConfirmPasswordReset(e.target.value)}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <button type="submit" className="btn btn-primary btn-block">
                  Change password
                </button>
              </div>
              {/* /.col */}
            </div>
          </form>
          <p className="mt-3 mb-1">
            <Link to={"/login"}>Login</Link>
          </p>
        </div>
        {/* /.login-card-body */}
      </div>
    </>
  );
}
