import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";

export default function AdminEdit() {
  const Navigate = useNavigate();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState([]);
  const [successStyle, setSuccessStyle] = useState({
    color: "black",
    display: "none",
  });
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });

  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userConfirmPassword, setUserConfirmPassword] = useState("");
 

  const [searchParams, setSearchParams] = useSearchParams();
  

  const params = {
    id: searchParams.get("id"),
  };
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };
  const getUser = async () => {
    try {
      const response = await axios
        .post(
          process.env.REACT_APP_API_URL + "V1/user/get-user",
          params,
          config
        )
        .then((response) => response.data)
        .catch((err) => console.error(err));
      setUserId(response.data.id);
      setUserName(response.data.name);
      setUserEmail(response.data.email);
    } catch (err) {
      console.log(err);
    }
  };

  const updateUser = (e) => {
    e.preventDefault();

    const params = {
      id: userId,
      name: userName,
      email: userEmail,
      password: userPassword,
      c_password: userConfirmPassword,
    };

   

    fetch(process.env.REACT_APP_API_URL + "V1/user/update-user", {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("_token"),
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
           
          setSuccess(res.message + ", redirecting to listing...");
          setErrorStyle({
            color: "red",
            display: "none",
          });
          setSuccessStyle({
            color: "black",
            display: "block",
          });

          setTimeout(() => {
            Navigate("/admins");
          }, 2000);
        } else {
          setError(res.message);
          setErrorStyle({
            color: "red",
            display: "block",
          });
        }
      });
    
  };
 

  useEffect(() => {
    getUser();
    
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Edit Admin</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/admins">Admins</Link>
                  </li>
                  <li className="breadcrumb-item active">Edit Admin</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card card-primary">
                  <div className="card-body" style={errorStyle}>
                    <h6>Errors</h6>
                    <ul>
                      {(() => {
                        if (error.name) {
                          return <li>{error.name}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.email) {
                          return <li>{error.email}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.password) {
                          return <li>{error.password}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.c_password) {
                          return <li>{error.c_password}</li>;
                        }
                      })()}
                    </ul>
                  </div>
                  <div className="card-body" style={successStyle}>
                    <h6>Success</h6>
                    <ul>
                      {(() => {
                        if (success) {
                          return <li>{success}</li>;
                        }
                      })()}
                    </ul>
                  </div>
                  <form onSubmit={updateUser}>
                    <input
                      type="hidden"
                      name="id"
                      value={searchParams.get("id")}
                    />
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputName"
                          placeholder="Enter Name"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter email"
                          value={userEmail}
                          onChange={(e) => setUserEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Password"
                          value={userPassword}
                          onChange={(e) => setUserPassword(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword2">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="exampleInputPassword2"
                          placeholder="Confirm Password"
                          value={userConfirmPassword}
                          onChange={(e) =>
                            setUserConfirmPassword(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </>
  );
}
