import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function CorporateCreate() {
  const Navigate = useNavigate();
  const [error, setError] = useState([]);
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });
  const [success, setSuccess] = useState("");
  const [successStyle, setSuccessStyle] = useState({
    color: "white",
    display: "none",
  });

  const [corpName, setCorpName] = useState("");

  const createNewCorporate = (e) => {
    e.preventDefault();

    const params = {
      name: corpName,
    };

    fetch(process.env.REACT_APP_API_URL + "V1/corporate/create-new-corporate", {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("_token"),
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
           
          setSuccess(res.message + ", redirecting to listing...");
          setErrorStyle({
            color: "red",
            display: "none",
          });
          setSuccessStyle({
            color: "black",
            display: "block",
          });

          setTimeout(() => {
            Navigate("/corporations");
          }, 2000);
        } else {
          setError(res.message);
          setErrorStyle({
            color: "red",
            display: "block",
          });
          setSuccessStyle({
            color: "black",
            display: "none",
          });
        }
      });
    return false;
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Create Corporation</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link to="/corporations">Corporation</Link>
                </li>
                <li className="breadcrumb-item active">Create Corporation</li>
              </ol>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card card-primary">
                <div className="card-body" style={errorStyle}>
                  <h6>Errors</h6>
                  <ul>
                    {(() => {
                      if (error.name) {
                        return <li>{error.name}</li>;
                      }
                    })()}
                  </ul>
                </div>
                <div className="card-body" style={successStyle}>
                  <h6>Success</h6>
                  <ul>
                    {(() => {
                      if (success) {
                        return <li>{success}</li>;
                      }
                    })()}
                  </ul>
                </div>
                <form onSubmit={createNewCorporate}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName"
                        placeholder="Enter Name"
                        value={corpName}
                        onChange={(e) => setCorpName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
