import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Datepicker from "react-datepicker";

export default function ChallengeCreate() {
  const Navigate = useNavigate();
  const [error, setError] = useState([]);
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });
  const [success, setSuccess] = useState("");
  const [successStyle, setSuccessStyle] = useState({
    color: "white",
    display: "none",
  });

  const [challengeName, setChallengeName] = useState("");
  const [challengeStartDate, setChallengeStartDate] = useState(null);
  const [challengeEndDate, setChallengeEndDate] = useState(null);
  const [challengeGoal, setChallengeGoal] = useState("");
  const [challengeFrequency, setChallengeFrequency] = useState("");
  const [challengePic, setChallengePic] = useState();

  const [selectedOption, setSelectedOption] = useState(0);
  const [challengeBillName, setChallengeBillName] = useState("");
  const [challengeBillAddress, setChallengeBillAddress] = useState("");

  const [charites, setCharites] = useState([]);
  const [charityId, setCharityId] = useState("");
  const [challengeDesc, setChallengeDesc] = useState("");
  const handleChangeCharityId = (e) => setCharityId(e.target.value);

  const [challengeType, setChallengeType] = useState([]);
  const [challengeTypeId, setChallengeTypeId] = useState("");
  const [month, setMonth] = useState("");

  const [charityStyle, setCharityStyle] = useState({
    display: "none",
  });

  const [charitySpecialstandStyle, setCharitySpecialstandStyle] = useState({
    display: "none",
  });

  const [goalStyle, setGoalStyle] = useState({
    display: "none",
  });

  const handleKeyDown = (e) => {
    // Check if the key pressed is a dot
    if (e.key === '.') {
      e.preventDefault(); // Prevent the dot from being entered
    }
  };

  const handleChange = (e) => {
    setChallengeGoal(e.target.value);
  };

  const handleChangeChallengeTypeId = (e) => {
    setChallengeTypeId(e.target.value);

    if (e.target.value == 1) {
      setCharityStyle({
        display: "none",
      });
      setCharitySpecialstandStyle({
        display: "block",
      });

      setGoalStyle({
        display: "block",
      });
    } else if (e.target.value == 4) {
      setCharitySpecialstandStyle({
        display: "none",
      });
      setCharityStyle({
        display: "block",
      });
      setGoalStyle({
        display: "block",
      });
    } else {
      setCharityStyle({
        display: "none",
      });
      setCharitySpecialstandStyle({
        display: "none",
      });
      setGoalStyle({
        display: "none",
      });
    }
  };

  let selectedMonthName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };

  const createNewChallenge = (e) => {
    e.preventDefault();

    let zero = "0";

    let formData = new FormData();
    formData.append("type", challengeTypeId);
    formData.append("image", challengePic);
    formData.append("name", challengeName);
    formData.append("charity", charityId);
    formData.append("month", month);

    if (challengeStartDate != null) {
      let sd = new Date(challengeStartDate);
      let sdm = sd.getMonth() + 1;
      if (sdm < 10) {
        sdm = zero.concat(sdm);
      }
      let startDate = `${sd.getFullYear()}-${sdm}-${sd.getDate()} 00:00:00`;
      formData.append("start_date", startDate);
    }

    if (challengeEndDate != null) {
      let ed = new Date(challengeEndDate);
      let edm = ed.getMonth() + 1;
      if (edm < 10) {
        edm = zero.concat(edm);
      }
      let endDate = `${ed.getFullYear()}-${edm}-${ed.getDate()} 00:00:00`;
      formData.append("end_date", endDate);
    }

    formData.append("goal", challengeGoal);
    formData.append("frequency", challengeFrequency);
    formData.append("created_by", localStorage.getItem("id"));
    formData.append("description", challengeDesc);
    formData.append("paymentmethod", selectedOption);
    if (selectedOption === 1) {
      formData.append("billname", challengeBillName);
      formData.append("billaddress", challengeBillAddress);
    } 

    if (!isNaN(localStorage.getItem("corporateId"))) {
      formData.append("corporate_id", localStorage.getItem("corporateId"));
    } else {
      formData.append("corporate_id", "");
    }

    axios
      .post(process.env.REACT_APP_API_URL + `V1/challenge/create`, formData, {
        headers: {
          // Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("_token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setSuccess(response.data.message + ", redirecting to listing...");
        setErrorStyle({
          color: "red",
          display: "none",
        });
        setSuccessStyle({
          color: "black",
          display: "block",
        });

        setTimeout(() => {
          Navigate("/challenges");
        }, 2000);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setErrorStyle({
          color: "red",
          display: "block",
        });
        setSuccessStyle({
          color: "black",
          display: "none",
        });

        console.log(error);
      });
  };

  const handleOnChange = (e) => {
    setChallengePic(e.target.files[0]);
  };

  const handleMonth = (e) => {
    setMonth(e);
  };

  const fetchCharity = async (page) => {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + `V1/charity/all`,
      config
    );

    setCharites(response.data.data);
    setCharityId(response.data.data[0].id);
  };
  const fetchChallengeType = async (page) => {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + `V1/challenge-type/all`,
      config
    );

    setChallengeType(response.data.data);
  };

  useEffect(() => {
    fetchCharity(); // fetch page 1 of users
    fetchChallengeType(); // fetch page 1 of users
  }, []);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Create Challenge</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link to="/Challenges">Challenge</Link>
                </li>
                <li className="breadcrumb-item active">Create Challenge</li>
              </ol>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card card-primary">
                <div className="card-body" style={errorStyle}>
                  <h6>Errors</h6>
                  <ul>
                    {(() => {
                      if (error.type) {
                        return <li>{error.type}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.name) {
                        return <li>{error.name}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.image) {
                        return <li>{error.image}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.start_date) {
                        return <li>{error.start_date}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.end_date) {
                        return <li>{error.end_date}</li>;
                      }
                    })()}
                  </ul>
                </div>
                <div className="card-body" style={successStyle}>
                  <h6>Success</h6>
                  <ul>
                    {(() => {
                      if (success) {
                        return <li>{success}</li>;
                      }
                    })()}
                  </ul>
                </div>
                <form onSubmit={createNewChallenge}>
                  <div className="card-body">
                    {1 == localStorage.getItem("role") ? (
                      <>
                        <div className="form-group">
                          For A Cause Challenge recur monthly,So start date and
                          end date will be generated automatically.
                        </div>
                        <div className="form-group">
                          Special Stand Challenge are month wise so we added
                          month dropdown to select challenge month.
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="form-group">
                      <div className="form-group">
                        <label>Type</label>
                        <select
                          className="form-control"
                          value={challengeTypeId}
                          onChange={handleChangeChallengeTypeId}
                        >
                          {" "}
                          <option value="">Select Type</option>
                          {challengeType.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div
                      className="form-group"
                      style={charitySpecialstandStyle}
                    >
                      <div className="form-group">
                        <label>Charity</label>
                        <select
                          className="form-control"
                          value={charityId}
                          onChange={handleChangeCharityId}
                        >
                          {charites.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="exampleInputName">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName"
                        placeholder="Enter Name"
                        value={challengeName}
                        onChange={(e) => setChallengeName(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputImage">Image</label>
                      <input
                        type={"file"}
                        id={"exampleInputImage"}
                        onChange={handleOnChange}
                        className="form-control"
                      />
                    </div>

                    {2 == localStorage.getItem("role") ? (
                      <>
                        <div className="form-group">
                          <label htmlFor="exampleInputStartDate">
                            Start Date
                          </label>

                          <Datepicker
                            id="exampleInputStartDate"
                            className="form-control"
                            wrapperClassName="datePicker"
                            selected={challengeStartDate}
                            onChange={(date) => setChallengeStartDate(date)}
                            dateFormat="yyyy-MM-dd"
                            autoComplete="off"
                            minDate={moment().toDate()}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEndDate">End Date</label>
                          <Datepicker
                            id="exampleInputStartDate"
                            className="form-control"
                            wrapperClassName="datePicker"
                            selected={challengeEndDate}
                            onChange={(date) => setChallengeEndDate(date)}
                            dateFormat="yyyy-MM-dd"
                            autoComplete="off"
                            minDate={moment().toDate()}
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div className="form-group" style={charityStyle}>
                      <label htmlFor="exampleInputGoal">Month</label>
                      <select
                        className="form-control"
                        onChange={(e) => handleMonth(e.target.value)}
                      >
                        <option value="">Select Month</option>
                        {selectedMonthName.map((item) => {
                          return <option value={item}>{item}</option>;
                        })}
                      </select>
                    </div>
                    <div className="form-group" style={goalStyle}>
                      <label htmlFor="exampleInputGoal">Goal ($)</label>
                      <input
                        type="number"
                        className="form-control"
                        id="exampleInputGoal"
                        placeholder="Goal"
                        value={challengeGoal}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputFrequency">Description</label>
                      <textarea
                        className="form-control"
                        id="exampleInputDescription"
                        placeholder="Description"
                        value={challengeDesc}
                        onChange={(e) => setChallengeDesc(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputFrequency">
                        Payment Method For Donation
                      </label>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="checkOption"
                          checked={selectedOption === 1}
                          onChange={(e) => {
                            const { checked } = e.target;
                            setSelectedOption(checked ? 1 : 0);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="checkOption"
                        >
                          Check
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="creditCardOption"
                          checked={selectedOption === 2}
                          onChange={(e) => {
                            const { checked } = e.target;
                            setSelectedOption(checked ? 2 : 0);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="creditCardOption"
                        >
                          Credit Card
                        </label>
                      </div>

                      {selectedOption === 1 && (
                        <div>
                          <h3 className="mb-4 mt-4">Billing Information</h3>
                          <div className="form-group">
                            <label htmlFor="billingName">Billing Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="billingName"
                              placeholder="Enter Billing Name"
                              value={challengeBillName}
                              onChange={(e) =>
                                setChallengeBillName(e.target.value)
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="billingAddress">
                              Billing Address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="billingAddress"
                              placeholder="Enter Billing Address"
                              value={challengeBillAddress}
                              onChange={(e) =>
                                setChallengeBillAddress(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
