import { useEffect, useState } from "react";
import moment from "moment";
import "./Content.css";
import { Link } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import BarGraph from "../GraphComponents/BarGraph/BarGraph";
import DemoGraphDoughnut from "../Assets/Images/pie_graph_zero.png";
import DoughnutChart from "../GraphComponents/DoughnutChart/DoughnutChart";
import { getQuickGlanceGraphsApi, getSuperAdminDashboardData } from "../Api/EndpointCalls/EndpointCalls";

export function Content() {
  const [doughVal, setDoughVal] = useState([]);
  const [openDateRange, setOpenDateRange] = useState(false);
  const [openDateRangeDoughnut, setOpenDateRangeDoughnut] = useState(false);
  const [dashData, setDashData] = useState({
    barGraph: { data: "", loading: true },
    doughnutChart: { data: "", loading: true },
    corpAdminCardsData: { totalChallenge: "", totalCharity: "", totalTeams: "", totalUsers: "", loading: true },
  });
  const [dashboardParams, setDashboardParams] = useState({
    fromDate: new Date(),
    toDate: new Date(),
    fromDateStepRange: new Date(),
    toDateStepRange: new Date(),
    typeBar: "team_avg_stood",
    // "time_length"
    // "time_stood"
    // "stand_range" ---> For Pie Chart!
  });
  const [superAdminDashData, setSuperAdminDashData] = useState({ data: null, loading: true });

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  const userId = localStorage.getItem("id");
  const corporateId = localStorage.getItem("corporateId");

  const columnsSignUpUSersTable = [
    {
      id: "id",
      name: "Id",
      selector: (row, i) => row.id,
      sortable: true,
    },
    {
      id: "name",
      name: "Name",
      selector: (row) => row.name.charAt(0).toUpperCase() + row.name.slice(1),
      sortable: true,
    },
    {
      id: "roleWithName",
      name: "Role",
      selector: (row) => (row.role === 2 && "Corporate Admin") || (row.role === 3 && "Corporate User") || (row.role === 4 && "Individual User"),
      // sortable: true,
    },
    {
      id: "signUpDate",
      name: "Sign Up Date",
      selector: (row) => row.signup_date,
      // sortable: true,
    },
  ];

  const fetchQuickGlanceActivationGraph = async (fromDate, toDate, uId, typeBar, corpId) => {
    try {
      const res = await getQuickGlanceGraphsApi(fromDate, toDate, uId, typeBar, corpId);
      setDashData((v) => ({
        ...v,
        barGraph: {
          data: res?.data?.data?.activity_distribution,
          loading: false,
        },
        corpAdminCardsData: {
          totalChallenge: res?.data?.data?.total_challenge,
          totalCharity: res?.data?.data?.total_charity,
          totalTeams: res?.data?.data?.total_team,
          totalUsers: res?.data?.data?.total_user,
        },
      }));
    } catch (error) {}
  };

  const fetchQuickGlanceStandRangesGraph = async (fromDate, toDate, uId, typeBar, corpId) => {
    try {
      const res = await getQuickGlanceGraphsApi(fromDate, toDate, uId, typeBar, corpId);
      setDashData((v) => ({
        ...v,
        doughnutChart: {
          data: res?.data?.data,
          loading: false,
        },
      }));
    } catch (error) {}
  };

  const fetchSuperAdminDashdata = async () => {
    try {
      const res = await getSuperAdminDashboardData();
      if (res?.status === 200) {
        setSuperAdminDashData((v) => ({
          ...v,
          data: res?.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleActivityDistributionGraph = (e) => {
    setDashboardParams((v) => ({
      ...v,
      typeBar: e.target.value,
    }));
    fetchQuickGlanceActivationGraph(
      moment(dashboardParams?.fromDate).format("YYYY-MM-DD"),
      moment(dashboardParams?.toDate).format("YYYY-MM-DD"),
      userId,
      e.target.value,
      corporateId
    );
  };

  const handleDateRange = (date, type) => {
    setDashboardParams((v) => ({ ...v, [type]: date }));
  };

  const handleDoughnutDataChange = (e) => {
    if (e.target.value === "stand_time") {
      return setDoughVal([
        dashData?.doughnutChart?.data?.stand_range?.high?.stand_time,
        dashData?.doughnutChart?.data?.stand_range?.low?.stand_time,
        dashData?.doughnutChart?.data?.stand_range?.medium?.stand_time,
        dashData?.doughnutChart?.data?.stand_range?.very_high?.stand_time,
      ]);
    } else if (e.target.value === "user_count") {
      return setDoughVal([
        dashData?.doughnutChart?.data?.stand_range?.high?.user_count,
        dashData?.doughnutChart?.data?.stand_range?.low?.user_count,
        dashData?.doughnutChart?.data?.stand_range?.medium?.user_count,
        dashData?.doughnutChart?.data?.stand_range?.very_high?.user_count,
      ]);
    } else if (e.target.value === "user_percentage") {
      return setDoughVal([
        dashData?.doughnutChart?.data?.stand_range?.high?.user_percentage,
        dashData?.doughnutChart?.data?.stand_range?.low?.user_percentage,
        dashData?.doughnutChart?.data?.stand_range?.medium?.user_percentage,
        dashData?.doughnutChart?.data?.stand_range?.very_high?.user_percentage,
      ]);
    }
  };

  const handleApplyDateFilter = (type) => {
    if (type === "actDist") {
      setOpenDateRange(!openDateRange);
      fetchQuickGlanceActivationGraph(
        moment(dashboardParams?.fromDate).format("YYYY-MM-DD"),
        moment(dashboardParams?.toDate).format("YYYY-MM-DD"),
        userId,
        dashboardParams?.typeBar,
        corporateId
      );
    } else if (type === "stepRange") {
      setOpenDateRangeDoughnut(!openDateRangeDoughnut);
      fetchQuickGlanceStandRangesGraph(
        moment(dashboardParams?.fromDateStepRange).format("YYYY-MM-DD"),
        moment(dashboardParams?.toDateStepRange).format("YYYY-MM-DD"),
        userId,
        "stand_range",
        corporateId
      );
    }
  };

  const checkALlZeros = (obj) => {
    return Object.keys(obj).every((key) => obj[key] === 0 || obj[key] === false || obj[key] === undefined);
  };

  useEffect(() => {
    const role = localStorage.getItem("role");

    if (role === "2") {
      fetchQuickGlanceActivationGraph(
        moment(dashboardParams?.fromDate).format("YYYY-MM-DD"),
        moment(dashboardParams?.toDate).format("YYYY-MM-DD"),
        userId,
        dashboardParams?.typeBar,
        corporateId
      );
      fetchQuickGlanceStandRangesGraph(
        moment(dashboardParams?.fromDateStepRange).format("YYYY-MM-DD"),
        moment(dashboardParams?.toDateStepRange).format("YYYY-MM-DD"),
        userId,
        "stand_range",
        corporateId
      );
    }

    if (role === "1") {
      fetchSuperAdminDashdata();
    }
  }, []);

  useEffect(() => {
    if (dashData?.doughnutChart?.data !== "") {
      setDoughVal([
        dashData?.doughnutChart?.data?.stand_range?.high?.user_percentage,
        dashData?.doughnutChart?.data?.stand_range?.low?.user_percentage,
        dashData?.doughnutChart?.data?.stand_range?.medium?.user_percentage,
        dashData?.doughnutChart?.data?.stand_range?.very_high?.user_percentage,
      ]);
    }
  }, [dashData?.doughnutChart?.data]);

  return (
    <>
      <div className='content-wrapper'>
        <div className='content-header'>
          <div className='container-fluid'>
            <div className='row mb-2'>
              <div className='col-sm-6'>
                <h1 className='m-0'>Dashboard</h1>
              </div>
              <div className='col-sm-6'>
                <ol className='breadcrumb float-sm-right'>
                  <li className='breadcrumb-item'>
                    <Link to='/'>Home</Link>
                  </li>
                  <li className='breadcrumb-item active'>Dashboard v1</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className='content'>
          <div className='container-fluid'>
            {localStorage.getItem("role") === "2" ? (
              <>
                <div className='row'>
                  <div className='col-xl-4 col-md-4 mb-1'>
                    <div className='card border-top-users-primary shadow h-100 py-2 m-0'>
                      <div className='col-auto justify-content-center'>
                        <div className='d-flex flex-column justify-content-center'>
                          <span className='fa-stack fa-lg align-self-center mb-2'>
                            <i className='fa fa-circle fa-stack-2x' style={{ color: "#4e73df" }}></i>
                            <i className='fa fa-user fa-stack-1x fa-inverse'></i>
                          </span>
                          <p className='align-self-center font-weight-bold'>TOTAL USERS</p>
                        </div>
                      </div>
                      <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                        <div className='col m-1'>
                          <div className='h5 mb-0 font-weight-bold text-gray-800 text-center'>
                            {dashData?.corpAdminCardsData?.totalUsers ? dashData?.corpAdminCardsData?.totalUsers : "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-4 col-md-4 mb-1'>
                    <div className='card border-top-users-daily-goal-primary shadow h-100 py-2 m-0'>
                      <div className='col-auto justify-content-center'>
                        <div className='d-flex flex-column justify-content-center'>
                          <span className='fa-stack fa-lg align-self-center mb-2'>
                            <i className='fa fa-circle fa-stack-2x' style={{ color: "rgb(0, 155, 132)" }}></i>
                            <i className='fa fa-users fa-stack-1x fa-inverse'></i>
                          </span>
                          <p className='align-self-center font-weight-bold'>TOTAL TEAMS</p>
                        </div>
                      </div>
                      <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                        <div className='col m-1'>
                          <div className='h5 mb-0 font-weight-bold text-gray-800 text-center'>
                            {dashData?.corpAdminCardsData?.totalTeams ? dashData?.corpAdminCardsData?.totalTeams : "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-4 col-md-4 mb-1'>
                    <div className='card border-top-users-active-in-chalenge-primary shadow h-100 py-2 m-0'>
                      <div className='col-auto justify-content-center'>
                        <div className='d-flex flex-column justify-content-center'>
                          <span className='fa-stack fa-lg align-self-center mb-2'>
                            <i className='fa fa-circle fa-stack-2x' style={{ color: "#7a3ec3" }}></i>
                            <i className='fa fa-list fa-stack-1x fa-inverse'></i>
                          </span>
                          <p className='align-self-center font-weight-bold'>TOTAL CHALLENGES</p>
                        </div>
                      </div>
                      <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                        <div className='col m-1'>
                          <div className='h5 mb-0 font-weight-bold text-gray-800 text-center'>
                            {dashData?.corpAdminCardsData?.totalChallenge ? dashData?.corpAdminCardsData?.totalChallenge : "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
                {/* Quick Glance Graphs - For now only for Corp Admin */}
                <div className='pb-4'>
                  <h3 className='text-center'>Quick Glance Graphs</h3>
                  <div className='d-flex row justify-content-around'>
                    {/* Activity Ditribution Graph */}
                    <div className='col-xl-6 col-md-6'>
                      <div className='graph-wrapper shadow border-top-users-active-in-chalenge-primary'>
                        <h4>Activity Distribution</h4>
                        <div className='row mb-2'>
                          <button
                            className='btn btn-primary m-2'
                            value={"team_avg_stood"}
                            onClick={(e) => {
                              handleActivityDistributionGraph(e);
                            }}>
                            Teams Average Stood
                          </button>
                          <button
                            className='btn btn-success m-2'
                            value={"time_length"}
                            onClick={(e) => {
                              handleActivityDistributionGraph(e);
                            }}>
                            Time Length
                          </button>
                          <button
                            className='btn btn-danger m-2'
                            value={"time_stood"}
                            onClick={(e) => {
                              handleActivityDistributionGraph(e);
                            }}>
                            Time Stood
                          </button>
                          {!openDateRange && (
                            <button
                              className='btn btn-info m-2'
                              onClick={() => {
                                setOpenDateRange(!openDateRange);
                              }}>
                              Select Date
                            </button>
                          )}
                        </div>
                        {openDateRange === true ? (
                          // <DateRangePicker
                          //   ranges={dateRangeForActivityDistribution}
                          //   className='date-range-picker'
                          //   maxDate={new Date()}
                          //   onChange={(item) => {
                          //     setDateRangeForActivityDistrubution([item.range1]);
                          //     setTimeout(() => {
                          //       setOpenDateRange(false);
                          //     }, 800);
                          //   }}
                          //   months={1}
                          //   // shownDate={dateRangeForActivityDistribution}
                          //   // showSelectionPreview={true}
                          //   moveRangeOnFirstSelection={false}
                          //   // showDateDisplay={false}
                          //   // inputRanges={[]}
                          //   direction='horizontal'
                          // />
                          <div className='row d-flex justify-content-around align-items-end'>
                            <div className='col-md-4 col-xl-4'>
                              <label htmlFor='exampleInputStartDate'>From Date</label>
                              <ReactDatePicker
                                id='exampleInputStartDate'
                                className='form-control'
                                selected={dashboardParams?.fromDate}
                                onChange={(date) => handleDateRange(date, "fromDate")}
                                dateFormat='yyyy-MM-dd'
                                autoComplete='off'
                                maxDate={moment().toDate()}
                              />
                            </div>
                            <div className='col-md-4 col-xl-4'>
                              <label htmlFor='exampleInputEndDate'>To Date</label>
                              <ReactDatePicker
                                id='exampleInputStartDate'
                                className='form-control'
                                selected={dashboardParams?.toDate}
                                onChange={(date) => handleDateRange(date, "toDate")}
                                dateFormat='yyyy-MM-dd'
                                autoComplete='off'
                                maxDate={moment().toDate()}
                                minDate={dashboardParams?.fromDate}
                              />
                            </div>
                            <div className='col-md-2 col-xl-2 align-items-end'>
                              <button
                                className='btn btn-info'
                                onClick={() => {
                                  handleApplyDateFilter("actDist");
                                }}>
                                Apply
                              </button>
                            </div>
                            <div className='col-md-2 col-xl-2 align-items-end'>
                              <button
                                className='btn btn-info'
                                onClick={() => {
                                  setOpenDateRange(!openDateRange);
                                }}>
                                Close
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <BarGraph dataset={dashData?.barGraph?.data} />
                      </div>
                    </div>
                    {/* Step Ranges Graph */}
                    <div className='col-xl-6 col-md-6'>
                      <div className='graph-wrapper shadow border-top-users-primary'>
                        <h4>Step Ranges Percentage of All Users</h4>
                        <div className='row'>
                          {/* <button
                          className='btn btn-primary m-2'
                          value={"stand_time"}
                          onClick={(e) => {
                            handleDoughnutDataChange(e);
                          }}>
                          Stand Time
                        </button>
                        <button
                          className='btn btn-success m-2'
                          value={"user_count"}
                          onClick={(e) => {
                            handleDoughnutDataChange(e);
                          }}>
                          User Count
                        </button> */}
                          {/* <button
                          className='btn btn-danger m-2'
                          value={"user_percentage"}
                          onClick={(e) => {
                            handleDoughnutDataChange(e);
                          }}>
                          User Percentage
                        </button> */}
                          {!openDateRangeDoughnut && (
                            <button
                              className='btn btn-info m-2'
                              onClick={(e) => {
                                setOpenDateRangeDoughnut(!openDateRangeDoughnut);
                              }}>
                              Select Date
                            </button>
                          )}
                        </div>
                        {openDateRangeDoughnut === true ? (
                          <div className='row d-flex justify-content-around align-items-end'>
                            <div className='col-md-4 col-xl-4'>
                              <label htmlFor='exampleInputEndDate'>From Date</label>
                              <ReactDatePicker
                                id='exampleInputStartDate'
                                className='form-control'
                                selected={dashboardParams?.fromDateStepRange}
                                onChange={(date) => handleDateRange(date, "fromDateStepRange")}
                                dateFormat='yyyy-MM-dd'
                                autoComplete='off'
                                maxDate={moment().toDate()}
                              />
                            </div>
                            <div className='col-md-4 col-xl-4'>
                              <label htmlFor='exampleInputEndDate'>To Date</label>
                              <ReactDatePicker
                                id='exampleInputStartDate'
                                className='form-control'
                                selected={dashboardParams?.toDateStepRange}
                                onChange={(date) => handleDateRange(date, "toDateStepRange")}
                                dateFormat='yyyy-MM-dd'
                                autoComplete='off'
                                maxDate={moment().toDate()}
                                minDate={dashboardParams?.fromDateStepRange}
                              />
                            </div>
                            <div className='col-md-2 col-xl-2 align-items-end'>
                              <button
                                className='btn btn-info'
                                onClick={(e) => {
                                  handleApplyDateFilter("stepRange");
                                }}>
                                Apply
                              </button>
                            </div>
                            <div className='col-md-2 col-xl-2 align-items-end'>
                              <button
                                className='btn btn-info'
                                onClick={(e) => {
                                  setOpenDateRangeDoughnut(!openDateRangeDoughnut);
                                }}>
                                Close
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className='row'>
                          <div className='doughnut-chart-wrapper col-5'>
                            {!checkALlZeros(doughVal) ? (
                              <DoughnutChart dataset={doughVal} />
                            ) : (
                              <div>
                                <img alt="" className='doughnut-demo-img' src={DemoGraphDoughnut} />
                              </div>
                            )}
                          </div>
                          <div className='col-7 align-self-center mt-4'>
                            <p>
                              <i className='fas fa-circle px-4' style={{ color: "#e36262" }}></i>
                              {`Low (0-24 Minutes) - ${doughVal[1] || 0}%`}
                            </p>
                            <p>
                              <i className='fas fa-circle px-4' style={{ color: "#faa346" }}></i>
                              {`Moderate (24-64 Minutes) - ${doughVal[2] || 0}%`}
                            </p>
                            <p>
                              <i className='fas fa-circle px-4' style={{ color: "#56a86c" }}></i>
                              {`High (65-96 Minutes}) - ${doughVal[0] || 0}%`}
                            </p>
                            <p>
                              <i className='fas fa-circle px-4' style={{ color: "#2374ba" }}></i>
                              {`Very High (97-128 Minutes) - ${doughVal[3] || 0}%`}
                            </p>
                          </div>
                          {/* <div className='col d-flex justify-content-end'>
                          <button
                            className='btn btn-info m-2'
                            value={"user_percentage"}
                            onClick={(e) => {
                              // fetchQuickGlanceStandRangesGraph(
                              //   moment(dashboardParams?.fromDateStepRange).format("YYYY-MM-DD"),
                              //   moment(dashboardParams?.toDateStepRange).format("YYYY-MM-DD"),
                              //   userId,
                              //   "stand_range",
                              //   corporateId
                              // );
                            }}>
                            Reset
                          </button>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='row'>
                  <div className='col-xl-3 col-md-3 mb-2'>
                    <div className='card border-top-users-primary shadow h-100 py-2 m-0'>
                      <div className='col-auto justify-content-center'>
                        <div className='d-flex flex-column justify-content-center'>
                          <span className='fa-stack fa-lg align-self-center mb-2'>
                            <i className='fa fa-circle fa-stack-2x' style={{ color: "#4e73df" }}></i>
                            <i className='fa fa-users fa-stack-1x fa-inverse'></i>
                          </span>
                          <p className='align-self-center font-weight-bold'>TOTAL USERS</p>
                        </div>
                      </div>
                      <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                        <div className='col m-1'>
                          <div className='h5 mb-0 font-weight-bold text-gray-800 text-center'>
                            {superAdminDashData?.data?.data?.total_user ? superAdminDashData?.data?.data?.total_user : "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3 col-md-3 mb-2'>
                    <div className='card border-top-users-daily-goal-primary shadow h-100 py-2 m-0'>
                      <div className='col-auto justify-content-center'>
                        <div className='d-flex flex-column justify-content-center'>
                          <span className='fa-stack fa-lg align-self-center mb-2'>
                            <i className='fa fa-circle fa-stack-2x' style={{ color: "rgb(0, 155, 132)" }}></i>
                            <i className='fa fas fa-building fa-stack-1x fa-inverse'></i>
                          </span>
                          <p className='align-self-center font-weight-bold'>TOTAL CORPORATIONS</p>
                        </div>
                      </div>
                      <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                        <div className='col m-1'>
                          <div className='h5 mb-0 font-weight-bold text-gray-800 text-center'>
                            {superAdminDashData?.data?.data?.total_corporation ? superAdminDashData?.data?.data?.total_corporation : "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3 col-md-3 mb-2'>
                    <div className='card border-top-users-active-in-chalenge-primary shadow h-100 py-2 m-0'>
                      <div className='col-auto justify-content-center'>
                        <div className='d-flex flex-column justify-content-center'>
                          <span className='fa-stack fa-lg align-self-center mb-2'>
                            <i className='fa fa-circle fa-stack-2x' style={{ color: "#7a3ec3" }}></i>
                            <i className='fa fa-users fa-stack-1x fa-inverse'></i>
                          </span>
                          <p className='align-self-center font-weight-bold'>TOTAL CORPORATE USERS</p>
                        </div>
                      </div>
                      <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                        <div className='col m-1'>
                          <div className='h5 mb-0 font-weight-bold text-gray-800 text-center'>
                            {superAdminDashData?.data?.data?.total_corp_user ? superAdminDashData?.data?.data?.total_corp_user : "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3 col-md-3 mb-2'>
                    <div className='card border-top-users-one shadow h-100 py-2 m-0'>
                      <div className='col-auto justify-content-center'>
                        <div className='d-flex flex-column justify-content-center'>
                          <span className='fa-stack fa-lg align-self-center mb-2'>
                            <i className='fa fa-circle fa-stack-2x' style={{ color: "#f36a26" }}></i>
                            <i className='fa fa-user fa-stack-1x fa-inverse'></i>
                          </span>
                          <p className='align-self-center font-weight-bold'>TOTAL INDIVIDUAL USERS</p>
                        </div>
                      </div>
                      <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                        <div className='col m-1'>
                          <div className='h5 mb-0 font-weight-bold text-gray-800 text-center'>
                            {superAdminDashData?.data?.data?.total_individual_user ? superAdminDashData?.data?.data?.total_individual_user : "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3 col-sm-3 mb-2'>
                    <div className='card border-top-users-one shadow h-100 py-2 m-0'>
                      <div className='col-auto justify-content-center'>
                        <div className='d-flex flex-column justify-content-center'>
                          <span className='fa-stack fa-lg align-self-center mb-2'>
                            <i className='fa fa-circle fa-stack-2x' style={{ color: "#f36a26" }}></i>
                            <i className='fab fa-angellist fa-stack-1x fa-inverse'></i>
                          </span>
                          <p className='align-self-center font-weight-bold text-center'>TOTAL FOR A CAUSE CHALLENGES</p>
                        </div>
                      </div>
                      <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                        <div className='col m-1'>
                          <div className='h5 mb-0 font-weight-bold text-gray-800 text-center'>
                            {superAdminDashData?.data?.data?.total_foracause ? superAdminDashData?.data?.data?.total_foracause : "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3 col-sm-3 mb-2'>
                    <div className='card border-top-users-active-in-chalenge-primary shadow h-100 py-2 m-0'>
                      <div className='col-auto justify-content-center'>
                        <div className='d-flex flex-column justify-content-center'>
                          <span className='fa-stack fa-lg align-self-center mb-2'>
                            <i className='fa fa-circle fa-stack-2x' style={{ color: "#7a3ec3" }}></i>
                            <i className='fa fas fa-shoe-prints fa-stack-1x fa-inverse'></i>
                          </span>
                          <p className='align-self-center font-weight-bold text-center'>TOTAL SPECIAL STAND CHALLENGES</p>
                        </div>
                      </div>
                      <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                        <div className='col m-1'>
                          <div className='h5 mb-0 font-weight-bold text-gray-800 text-center'>
                            {superAdminDashData?.data?.data?.total_special_stand ? superAdminDashData?.data?.data?.total_special_stand : "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3 col-sm-3 mb-2'>
                    <div className='card border-top-users-daily-goal-primary shadow h-100 py-2 m-0'>
                      <div className='col-auto justify-content-center'>
                        <div className='d-flex flex-column justify-content-center'>
                          <span className='fa-stack fa-lg align-self-center mb-2'>
                            <i className='fa fa-circle fa-stack-2x' style={{ color: "rgb(0, 155, 132)" }}></i>
                            <i className='fas fa-running fa-stack-1x fa-inverse'></i>
                          </span>
                          <p className='align-self-center font-weight-bold'>TOTAL FITNESS CHALLENGES</p>
                        </div>
                      </div>
                      <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                        <div className='col m-1'>
                          <div className='h5 mb-0 font-weight-bold text-gray-800 text-center'>
                            {superAdminDashData?.data?.data?.total_fitness ? superAdminDashData?.data?.data?.total_fitness : "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3 col-sm-3 mb-2'>
                    <div className='card border-top-users-primary shadow h-100 py-2 m-0'>
                      <div className='col-auto justify-content-center'>
                        <div className='d-flex flex-column justify-content-center'>
                          <span className='fa-stack fa-lg align-self-center mb-2'>
                            <i className='fa fa-circle fa-stack-2x' style={{ color: "#4e73df" }}></i>
                            <i className='fa fas fa-bullseye fa-stack-1x fa-inverse'></i>
                          </span>
                          <p className='align-self-center font-weight-bold'>TOTAL HABIT CHALLENGES</p>
                        </div>
                      </div>
                      <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                        <div className='col m-1'>
                          <div className='h5 mb-0 font-weight-bold text-gray-800 text-center'>
                            {superAdminDashData?.data?.data?.total_habit ? superAdminDashData?.data?.data?.total_habit : "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row pb-2'>
                  <div className='col-md-12 col-xl-12'>
                    <div className='card shadow border-top-yellow'>
                      <h5 className='text-center mt-1 mb-0'>RECENT SIGN UP USERS</h5>
                      <DataTable
                        columns={columnsSignUpUSersTable}
                        data={superAdminDashData?.data?.data?.recent_signup_users}
                        // progressPending={reportData?.loading}
                        // pagination
                        // paginationServer
                        paginationPerPage={5}
                        paginationTotalRows={10}
                        paginationRowsPerPageOptions={[5]}
                        // onChangeRowsPerPage={handlePerRowsChange}
                        // onChangePage={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
}
