import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

export default function CharityDetail() {
  const [charityId, setCharityId] = useState();
  const [charityName, setCharityName] = useState();
  const [charityDesc, setCharityDesc] = useState("");
  const [charityImage, setCharityImage] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const params = {
    id: searchParams.get("id"),
  };
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };
  const getCharity = async () => {
    try {
      await axios
        .post(
          process.env.REACT_APP_API_URL + "V1/charity/get-charity",
          params,
          config
        )
        .then((response) => {
          
          setCharityId(response.data.data.id);
          setCharityName(response.data.data.name);
          setCharityDesc(response.data.data.description);
          setCharityImage(response.data.data.image);
        })
        .then((response) => {});
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getCharity();
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Charity Detail</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/charity/all">Charities</Link>
                  </li>
                  <li className="breadcrumb-item active">Details</li>
                </ol>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                   {1 == localStorage.getItem("role") ? (
                    <>
                    <Link
                      to={`/charity/edit?id=${charityId}`}
                      className="btn btn-primary mb-2"
                    >Edit
                    </Link>
                    </>
                    ) : (
                      ""
                    )}
                    <table className="table table-striped">
                     <thead><tr>
                        <td>Name</td>
                        <td>{charityName}</td>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <td>{charityDesc}</td>
                      </tr>
                      <tr>
                        <td>Image</td>
                        <td>{(() => {
                          if (charityImage !== '') {
                            return <img alt="" src={process.env.REACT_APP_IMAGE_URL + '/storage/' + charityImage} width="30%" height ="40%"/>;
                          }
                        })()}</td>
                      </tr></thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
