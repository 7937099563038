import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

export default function CategoryEdit() {
  const Navigate = useNavigate();
  const [error, setError] = useState([]);
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });
  const [success, setSuccess] = useState("");
  const [successStyle, setSuccessStyle] = useState({
    color: "white",
    display: "none",
  });

  const [categoryName, setCategoryName] = useState();
  const [categoryDesc, setCategoryDesc] = useState();

  const [searchParams, setSearchParams] = useSearchParams();

  const updateCategory = (e) => {
    e.preventDefault();

    const params = {
      id: searchParams.get("id"),
      name: categoryName,
      desc: categoryDesc,
    };

    axios
      .post(process.env.REACT_APP_API_URL + `V1/category/update`, params, {
        headers: {
          // Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("_token"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        
        setSuccess(response.data.message + ", redirecting to listing...");
        setErrorStyle({
          color: "red",
          display: "none",
        });
        setSuccessStyle({
          color: "black",
          display: "block",
        });

        setTimeout(() => {
          Navigate("/category/all");
        }, 2000);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setErrorStyle({
          color: "red",
          display: "block",
        });
        setSuccessStyle({
          color: "black",
          display: "none",
        });
        
      });
  };

  useEffect(() => {
    getCategory();
  }, []);

  const params = {
    id: searchParams.get("id"),
  };
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };

  const getCategory = async () => {
    try {
       await axios
        .post(
          process.env.REACT_APP_API_URL + "V1/category/get-category",
          params,
          config
        )
        .then((response) => {
           
          setCategoryName(response.data.data.name);
          setCategoryDesc(response.data.data.description);
        })
        .then((response) => {});
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Edit Category</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link to="/category/all">Category</Link>
                </li>
                <li className="breadcrumb-item active">Edit category</li>
              </ol>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card card-primary">
                <div className="card-body" style={errorStyle}>
                  <h6>Errors</h6>
                  <ul>
                    {(() => {
                      if (error.name) {
                        return <li>{error.name}</li>;
                      }
                    })()}
                  </ul>
                </div>
                <div className="card-body" style={successStyle}>
                  <h6>Success</h6>
                  <ul>
                    {(() => {
                      if (success) {
                        return <li>{success}</li>;
                      }
                    })()}
                  </ul>
                </div>
                <form onSubmit={updateCategory}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName"
                        placeholder="Enter Name"
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputDescription">
                        Description
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="exampleInputDescription"
                        placeholder="Enter Description"
                        value={categoryDesc}
                        onChange={(e) => setCategoryDesc(e.target.value)}
                        rows={5}
                      />
                    </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
