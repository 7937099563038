import { Link } from "react-router-dom";

export function Header() {
  return (
    <>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="/#"
              role="button"
            >
              <i className="fas fa-bars"></i>
            </a>
          </li>
        </ul>

        <ul className="navbar-nav ml-auto mc-space">
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/profile" className="nav-link">
              <i className="fas fa-user"></i> &nbsp;
              Profile
            </Link>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/logout" className="nav-link">
              <i className="fas fa-sign-out-alt"></i> &nbsp;
              Logout
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}
