import axios from "axios";
import React, { useState } from "react";
import { CSVLink } from "react-csv";

export default function IndividualUserImport() {
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const [message, setMessage] = useState("");
  const [duplicateEntry, setDuplicateEntry] = useState([]);
  const [csvStyle, setCsvStyle] = useState({
    color: "black",
    display: "none",
  });

  const [buttonStyle, setButtonStyle] = useState({
    color: "white",
    display: "none",
  });

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    setArray(array);
     
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);

      setButtonStyle({
        color: "red",
        display: "block",
      });
    }
  };

  const headerKeys = Object.keys(Object.assign({}, ...array));

  const saveData = async () => {
    let formData = new FormData();
    formData.append("file", file);
    // You should have a server side REST API
    await axios
      .post(
        process.env.REACT_APP_API_URL + "V1/user/save-csv-user",
        formData,
        {
          headers: {
            // Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("_token"),
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        
        setMessage(response.data.msg);
        setDuplicateEntry(response.data.duplicate_entry);
        setCsvStyle({
          color: "black",
          display: "block",
        });
      })
      .catch(function () {
         
      });
  };

  const csvData = [
    ["username", "email", "password"],
    ["demouser", "demouser@gmail.com", "password"],
  ];

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-12">
              <div>
                <h1>Import Individual User </h1>
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="form-group">
                        <label>Select File</label>
                        <input
                          type={"file"}
                          id={"csvFileInput"}
                          accept={".csv"}
                          onChange={handleOnChange}
                          className="form-control"
                        />
                      </div>

                      <div className="form-group">
                        <button
                          className="btn btn-primary"
                          onClick={(e) => {
                            handleOnSubmit(e);
                          }}
                        >
                          IMPORT CSV
                        </button>
                        <CSVLink
                          filename={"sample.csv"}
                          data={csvData}
                          style={{ float: "right" }}
                        >
                          Download sample CSV
                        </CSVLink>
                      </div>
                    </form>
                  </div>
                </div>

                <br />

                <table className="table">
                  <thead>
                    <tr key={"header"}>
                      {headerKeys.map((key) => (
                        <th>{key}</th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {array.map((item) => (
                      <tr key={item.id}>
                        {Object.values(item).map((val) => (
                          <td>{val}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="" style={csvStyle}>
                <p>{message}</p>
                <p>Duplicate Entries are : {duplicateEntry}</p>
              </div>
              <div className="form-group" style={buttonStyle}>
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    saveData(e);
                  }}
                >
                  Save CSV to Data
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
