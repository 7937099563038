import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

export default function BlogEdit() {
  const Navigate = useNavigate();
  const [error, setError] = useState([]);
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });
  const [success, setSuccess] = useState("");
  const [successStyle, setSuccessStyle] = useState({
    color: "white",
    display: "none",
  });

  const [blogTitle, setBlogTitle] = useState();
  const [blogContent, setBlogContent] = useState();

  const [searchParams, setSearchParams] = useSearchParams();

  const updateBlog = (e) => {
    e.preventDefault();

    const params = {
      id: searchParams.get("id"),
      title: blogTitle,
      content: blogContent,
    };

    axios
      .post(process.env.REACT_APP_API_URL + `V1/blog/update`, params, {
        headers: {
          // Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("_token"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
         
        setSuccess(response.data.message + ", redirecting to listing...");
        setErrorStyle({
          color: "red",
          display: "none",
        });
        setSuccessStyle({
          color: "black",
          display: "block",
        });

        setTimeout(() => {
          Navigate("/blog/all");
        }, 2000);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setErrorStyle({
          color: "red",
          display: "block",
        });
        setSuccessStyle({
          color: "black",
          display: "none",
        });
         
      });
  };

  useEffect(() => {
    getBlogs();
  }, []);

  const params = {
    id: searchParams.get("id"),
  };
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };

  const getBlogs = async () => {
    try {
      await axios
        .post(
          process.env.REACT_APP_API_URL + "V1/blog/get-blog",
          params,
          config
        )
        .then((response) => {
           
          setBlogTitle(response.data.data.title);
          setBlogContent(response.data.data.content);
        })
        .then((response) => {});
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Edit Blog</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link to="/blog/all">Blog</Link>
                </li>
                <li className="breadcrumb-item active">Edit blog</li>
              </ol>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card card-primary">
                <div className="card-body" style={errorStyle}>
                  <h6>Errors</h6>
                  <ul>
                    {(() => {
                      if (error.title) {
                        return <li>{error.title}</li>;
                      }
                    })()}
                  </ul>
                </div>
                <div className="card-body" style={successStyle}>
                  <h6>Success</h6>
                  <ul>
                    {(() => {
                      if (success) {
                        return <li>{success}</li>;
                      }
                    })()}
                  </ul>
                </div>
                <form onSubmit={updateBlog}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="inputTitle">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputTitle"
                        placeholder="Enter Title"
                        value={blogTitle}
                        onChange={(e) => setBlogTitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputDescription">Content</label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="exampleInputDescription"
                        placeholder="Enter Description"
                        value={blogContent}
                        onChange={(e) => setBlogContent(e.target.value)}
                        rows={5}
                      />
                    </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
