import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link,useNavigate} from "react-router-dom";
 
export default function Admins() {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };

  ////////////////////////////////
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const columns = [
    {
      id: "id",
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      id: "name",
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      id: "email",
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      cell: (row) => (
        <>
          <Link className="btn btn-info btn-sm btn-space-custom" to={`/admin/edit?id=${row.id}`}>
            Edit
          </Link>{" "}
          {row.id !== localStorage.getItem("id") ? (
            <>
              <Link
                className="btn btn-danger btn-sm btn-space-custom"
                title="Delete"
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure to delete this user? Action cannot be reversed."
                    )
                  ) {
                    handleDelete(row.id);
                  }
                }}
              >
               Delete
              </Link>
              <button
                className="btn btn-danger btn-sm btn-space-custom"
                onClick={(e) => {
                  handleBlock(e, row.id);
                }}
              >
                {row.status ? "Block" : "Unblock"}
              </button>
            </>
          ) : (
            ""
          )}{" "}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      // button: true,
    },
  ];

  const handleBlock = async (e, id) => {
    
    const params = {
      user_id: id,
    };
    await axios
      .post(
        process.env.REACT_APP_API_URL + `V1/user/handle-block-user`,
        params,
        config
      )
      .then((response) => {
        setMessage(<><div>
          <div className="alert alert-danger-custom">{response.data.message}</div>
        </div></>);
        if (e.target.innerHTML === "Block") {
          e.target.innerHTML = "Unblock";
        } else {
          e.target.innerHTML = "Block";
        }
        setTimeout(() => {
          setMessage('');
        }, 2000);
      });
  };
  const handleDelete = async (id) => {
    const params = {
      user_id: id,
    };
    await axios
      .post(process.env.REACT_APP_API_URL + `V1/user/delete`, params, config)
      .then((response) => {
        setMessage(<><div>
          <div className="alert alert-danger-custom">{response.data.message}</div>
        </div></>);
      });

      setTimeout(() => {
        setMessage('');
      }, 2000);
  };

  const fetchUsers = async (page) => {
    setLoading(true);
    try { 
    const response = await axios.get(
      process.env.REACT_APP_API_URL +
        `V1/user/get-all-admins?page=${page}&per_page=${perPage}&delay=1`,
      config
    );
    setData(response.data.data);
    setTotalRows(response.data.total);
    setLoading(false);
  }
  catch (error) {
    if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login"); 
       
    } else {
      console.error('API error:', error.message);
    }
  }
  };

  const handlePageChange = (page) => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const response = await axios.get(
      process.env.REACT_APP_API_URL +
        `V1/user/get-all-admins?page=${page}&per_page=${newPerPage}&delay=1`,
      config
    );

    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const handleSearcFilter = async (search, newPerPage = 10, page = 1) => {
    setLoading(true);

    const response = await axios.get(
      process.env.REACT_APP_API_URL +
        `V1/user/get-all-admins?page=${page}&per_page=10&delay=1&search=${search}`,
      config
    );

    setTotalRows(response.data.total);
    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    
      fetchUsers(1); // fetch page 1 of users
    
  }, []);
  ////////////////////////////////
  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Admin</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Admin</li>
                </ol>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                    <div className="col-md-12">
                       {message}  
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Search"
                            onChange={(e) => handleSearcFilter(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-2"></div>
                      
                       
                    </div>
                    <DataTable
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      // defaultSortFieldId="id"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </>
  );
}
